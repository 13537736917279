import React, { useState, useEffect } from "react";
import UploadImage from "../../../assets/upload_icon.svg";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import AddIcon from "@mui/icons-material/Add";
import FaarmsLogo from "../../../assets/faarms_logo.png";
import CILogo from "../../../assets/CI_Logo.png";
import {
  LocationOn,
  ArrowBack,
  Close,
  AddCircle,
  Add,
  Cancel,
  Search,
  Scale,
  Sledding,
} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import { Button, Modal } from "antd";
import axios from "axios";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import * as moment from "moment";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import reactRouterDom, { useHistory } from "react-router-dom";
import MONTHLYICON from "../../../assets/monthly_icon.svg";
import TRIPWISEICON from "../../../assets/trip_wise_icon.svg";
import TICKICON from "../../../assets/green_tick.svg";
import WRONGICON from "../../../assets/wrong.svg";
import {
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
  TextareaAutosize,
  Checkbox,
  Dialog,
} from "@mui/material";
import {
  GetAddressFromPincode,
  CreateTripPost,
  GetUnitList,
  GetAvailableVehiclesList,
  GetPurchaseOrders,
  GetInvoices,
  UpdateTripPut,
  GetVehicles,
  GetDrivers,
  CreateDriverPost,
  GetDocumentEnums,
  GetCustomersList,
  getZohoInvoiceItemsDetails,
  GetEntityTypeEnums,
  GetCustomerSearchList,
  GetCustomerById,
  getSettingsListByCustomerId,
} from "./createTripServices";
import { Box, Drawer } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDriverDetails,
  updateTripDetails,
} from "../../../redux/tripSlice";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import apiUrl from "../../../url/apiUrl";
import { TripAttachmentPost } from "../tripList/tripListServices";
import { getWarehouseList } from "../../warehouses/warehousesServices";
import { getWarehouseUsersList } from "../../../components/warehouseMiniCard/warehouseMiniService";
import { useParams } from "react-router-dom";
// import { vendorsPost } from "../../vendors/VendorsServices";
import {
  Create_Trip_Main_Container,
  Create_trip_drawer_container,
  Create_trip_vehicle_single,
  Summary_container,
} from "./create-tripv2.js";
import { colorPalette } from "../../../themes/theme";
import { customersPost } from "../../customers/CustomersServices";
import Topbar from "../../../components/topbar/Topbar.jsx";
import axiosInterceptor from "../../../config/https";

import styled from "styled-components";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { getFtlTripDetailsFromId } from "../completeTrip/completeTripServices.js";
import { allRegex } from "../../../utility/regex.js";
import { saveBlob } from "../../../utility/saveBlobImage.js";
import { getTeams } from "../../teams/TeamsService.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Pincode from "../../../components/countryBasePincode/Pincode.jsx";
import { ButtonRectangle } from "../../../themes/components/button.js";

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const filterBoxStyle = {
  width: "90vw",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  border: "1px solid gray",
  borderRadius: "10px",
};

function CreateTripV2() {
  const [checked, setChecked] = useState(false);
  const [checkedAttachmentsNotRequired, setCheckedAttachmentsNotRequired] =
    useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeCheckedAttachments = (event) => {
    setCheckedAttachmentsNotRequired(event.target.checked);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };
  const [openSummary, setOpenSummary] = useState(false);
  const [facilitatedByModal, setFacilitatedByModal] = useState(false);

  useEffect(() => {
    if (openSummary == false) {
      setErrCreateTripFinal("");
    }
  }, [openSummary]);
  const history = useHistory();
  const { tripId, trip_type } = useParams();
  const removedStringForTripType = (trip_type) => {
    if (trip_type === "Faarms_first_mile" || trip_type === "Faarms_last_mile") {
      return trip_type.replace("Faarms_", "");
    } else if (
      trip_type === "Customer_first_mile" ||
      trip_type === "Customer_middle_mile" ||
      trip_type === "Customer_last_mile"
    ) {
      return trip_type.replace("Customer_", "");
    }
  };

  const [vehicleDetails, setVehicleDetails] = useState({
    manufacturer: "",
    model: "",
    payout: "",
    status: "",
  });

  const [transporterDetails, setTransporterDetails] = useState({
    transporter: "",
    code: "",
    mobile: "",
    district: "",
    state: "",
  });
  const [isSomethingWentWrongError, setIsSomethingWentWrongError] =
    useState(false);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState({
    first_name: "",
    last_name: "",
    dl: "",
    mobile: "",
  });
  const [tripSettingsList, setTripSettingsList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [errorAddressName, setErrorAddressName] = useState("");
  const [editAddressDeleteIndex, setEditAddressDeleteIndex] = useState("");
  const [errorAddressNumber, setErrorAddressNumber] = useState("");
  const [errorAddressPincode, setErrorAddressPincode] = useState("");
  const [errorAddressVillage, setErrorAddressVillage] = useState("");
  const dispatch = useDispatch();
  const [sourceVillagesList, setSourceVillagesList] = useState([]);
  const [toPay, setToPay] = useState("");
  const [toBilled, setToBilled] = useState("");
  const [drivingPhoto, setDrivingPhoto] = useState(null);
  const [driverPanImage, setDriverPanImage] = useState();
  const [permission, setPermission] = useState("");
  const [driverId, setDriverId] = useState("");
  const [secondaryDriverId, setSecondaryDriverId] = useState("");
  const [invoiceTotalQuantity, setInvoiceTotalQuantity] = useState([]);
  const [wareHouseList, setWareHouseList] = useState([]);
  const [totalQty, setTotalQty] = useState("");
  const [facilitatedBy, setFacilitatedBy] = useState("");

  const [referenceNumberSearchValue, setReferenceNumberSearchValue] =
    useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [destinationVillagesList, setDestinationVillagesList] = useState([]);
  const [sourceDetails, setSourceDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);

  const faarmsAndConnectIndiaEmailRegex =
    /\b[A-Za-z0-9._%+-]+@(faarms\.com|bscxpress\.com|connectindia\.com)\b/;

  const [addressDetails, setAddressDetails] = useState({
    district: "",
    sub_district: "",
    state: "",
    postoffice: "",
    type: "",
  });
  const [selectedIndex, setSelectedIndex] = useState("");
  const [destinationAddressList, setDestinationAddressList] = useState([]);
  const [editId, setEditId] = useState("");
  //
  const [sourceAddressList, setSourceAddressList] = useState([]);
  const [addSourceAddressListForEdit, setAddSourceAddressListForEdit] =
    useState([]);
  const [
    addDestinationAddressListForEdit,
    setAddDestinationAddressListForEdit,
  ] = useState([]);
  const [deletedIdArray, setDeletedIdArray] = useState([]);
  const [deletedIdArrayForDestination, setDeletedIdArrayForDestination] =
    useState([]);

  const handleRemoveAddress = (data, indexToBeDeleted) => {
    const filteredArray = sourceAddressList.filter(
      (item, index) => index !== indexToBeDeleted
    );
    const filteredNewArray = addSourceAddressListForEdit.filter(
      (item, index) => index == indexToBeDeleted
    );
    addSourceAddressListForEdit.pop(filteredNewArray);
    setSourceAddressList(filteredArray);
    if (data.id) {
      setDeletedIdArray([...deletedIdArray, { id: data.id }]);
    }
  };
  const [newSourceDetails, setNewSourceDetails] = useState({
    // id: editMode ? editId : "",
    concern_person_name: "",
    concern_person_mobile_number: "",
    postcode: "",
    type: "source",
    village: "0",
    postoffice: "",
    sub_district: "",
    district: "",
    state: "",
    landmark: "",
    latitude: "",
    longitude: "",
  });

  function changehandle(e) {
    setNewSourceDetails({
      ...newSourceDetails,
      [e.target.name]: e.target.value,
    });
  }

  const handlePincodeNewSource = (data, errorMsg) => {
    if (data) {
      setErrPincodeNotServiceable("");
      setNewSourceDetails({
        ...newSourceDetails,
        postcode: data,
      });
      setErrorAddressPincode("");
    }
    if (errorMsg) {
      setErrorAddressPincode(errorMsg);
    } else {
      handleGetAddressSource(data);
    }
  };
  const handlePincodeDestination = (data, errorMsg) => {
    if (data) {
      setNewDestinationDetails({
        ...newdestinationDetails,
        postcode: data,
      });
      setErrorAddressPincode("");
      setErrPincodeNotServiceable("");
    }
    if (errorMsg) {
      setErrorAddressPincode(errorMsg);
    } else {
      handleGetAddressDestination(data);
    }
  };

  function handleSubmitSourceAddress() {
    let {
      concern_person_name,
      concern_person_mobile_number,
      postcode,
      type,
      village,
      postoffice,
      sub_district,
      district,
      landmark,
      latitude,
      longitude,
    } = newSourceDetails;

    if (concern_person_name == "") {
      return setErrorAddressName("Name is required");
    }
    if (concern_person_mobile_number == "") {
      return setErrorAddressNumber("mobile number is required.");
    }
    if (concern_person_mobile_number.length < 10) {
      return setErrorAddressNumber("mobile number should be 10 digits.");
    }
    if (postcode == "") {
      return setErrorAddressPincode("Pincode is required.");
    }
    if (village == "0") {
      return setErrorAddressVillage("Village is required.");
    }
    if (
      concern_person_name &&
      concern_person_mobile_number &&
      postcode &&
      village
    ) {
      setSourceAddressList([
        ...sourceAddressList,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetails,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);
      // }

      toggleDrawer("right", false);
    }
    if (editMode) {
      setAddSourceAddressListForEdit([
        ...addSourceAddressListForEdit,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetails,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);
      toggleDrawer("right", false);
    }
    //
    setNewSourceDetails({
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "",
      village: "",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
  }

  const [loadingButton, setLoadingButton] = useState(false);
  const [addressDetailsDestination, setAddressDetailsDestination] = useState({
    district: "",
    sub_district: "",
    state: "",
    postoffice: "",
    type: "",
  });
  const [percentageList, setPercentageList] = useState([
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
    "100",
  ]);

  const handleRemoveAddressDestination = (data, indexToBeDeleted) => {
    const filteredDestination = destinationAddressList.filter(
      (item, index) => index !== indexToBeDeleted
    );
    setDestinationAddressList(filteredDestination);

    const filteredNewArrayDestination = addDestinationAddressListForEdit.filter(
      (item, index) => index == indexToBeDeleted
    );
    addDestinationAddressListForEdit.pop(filteredNewArrayDestination);
    if (data.id) {
      setDeletedIdArrayForDestination([
        ...deletedIdArrayForDestination,
        { id: data.id },
      ]);
    }
  };
  const [destinationDetails, setDestinationDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [newdestinationDetails, setNewDestinationDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  function changehandleDestination(e) {
    setNewDestinationDetails({
      ...newdestinationDetails,
      [e.target.name]: e.target.value,
    });
  }

  function changhandleSubmitDestination() {
    let {
      concern_person_name,
      concern_person_mobile_number,
      postcode,
      type,
      village,
      postoffice,
      sub_district,
      district,
      landmark,
      latitude,
      longitude,
    } = newdestinationDetails;

    if (concern_person_name == "") {
      return setErrorAddressName("Name is required");
    }
    if (concern_person_mobile_number == "") {
      return setErrorAddressNumber("mobile number is required.");
    }
    if (concern_person_mobile_number.length < 10) {
      return setErrorAddressNumber("mobile number should be 10 digits.");
    }
    if (postcode == "") {
      return setErrorAddressPincode("Pincode is required.");
    }
    if (village == "") {
      return setErrorAddressVillage("Village is required.");
    }
    if (
      concern_person_name &&
      concern_person_mobile_number &&
      postcode &&
      village
    ) {
      setDestinationAddressList([
        ...destinationAddressList,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetailsDestination,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);

      toggleDrawer("right", false);
    }
    if (editMode) {
      setAddDestinationAddressListForEdit([
        ...addDestinationAddressListForEdit,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetailsDestination,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);

      toggleDrawer("right", false);
    }
    //
    setNewDestinationDetails({
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "",
      village: "",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    // setErrorAddressName("");
  }

  // ==============================edit address logic source=======================
  const newSourceAddressForEdit = {
    new: addSourceAddressListForEdit,
    changed: [],
    deleted: deletedIdArray,
    unchanged: sourceAddressList.filter((data) => data.id),
  };
  const newDestinationAddressForEdit = {
    new: addDestinationAddressListForEdit,
    changed: [],
    deleted: deletedIdArrayForDestination,
    unchanged: destinationAddressList.filter((data) => data.id),
  };

  // ===============================================================================
  const [driverDetails, setDriverDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
  });

  const [secondaryDriverDetails, setSecondaryDriverDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
  });
  const [vehicleNumber, setVehicleNumber] = useState("0");
  const [transporterNameId, setTransporterNameId] = useState("");

  const [selectedTransporterName, setSelectedTransporterName] = useState("");
  const [currentCustomerField, setCurrentCustomerField] = useState("");
  const [consignorId, setConsignorId] = useState("");
  const [vehiclePayoutPeriod, setVehiclePayoutPeriod] = useState("");
  const [loadSource, setLoadSource] = useState("manual");

  const [loadUnit, setLoadUnit] = useState("ton");

  const [invoiceId, setInvoiceId] = useState([]);
  const [searchInvoiceLoading, setSearchInvoiceLoading] = useState(false);
  const [perKmRate, setPerKmRate] = useState("");
  const [tripStartMeterReading, setTripStartMeterReading] = useState("");
  const [tripStartMeterReadingError, setTripStartMeterReadingError] =
    useState("");
  const [searchResultsList, setSearchResultsList] = useState([]);
  const [errTotalDriver, setErrTotalDriver] = useState("");
  const [errCustomerFinal, setErrCustomerFinal] = useState("");
  const [addCustomerData, setAddCustomerData] = useState({});
  const [purchaseOrderId, setPurchaseOrderId] = useState([]);
  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }
  const [errTrip, setErrTrip] = useState({
    errLoadSource: "",
    errReferenceId: "",
    errVehicleNumber: "",
    errTransporter: "",
    errLoadUnit: "",
    errTripDistance: "",
    errPaymentStatus: "",
    errPaymentTerms: "",
    errTotalCost: "",
    // errAdvanceCost: "",
    errTotalQty: "",
    errCustomer: "",
    errConsignee: "",
    errBillToParty: "",
    errTripStartMeterReading: "",
    errTripStartMeterReadingAttachment: "",
    errPerKmRate: "",
    errValueOfGoods: "",
    errCustomerBilledAmount: "",
    errCustomerAdvanceAmount: "",
    errTripType: "",
    errLrNumber: "",
    errEwayNumber: "",
    errEwayFrom: "",
    errEwayTo: "",
    quantity_in_tonnes: "",
    no_of_pieces: "",
    customer_invoice: "",
    operationSPOC: "",
    customerInvoiceDate: "",
  });

  const [paymentTerms, setPaymentTerms] = useState("");
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);

  const [ewayBillAttachment, setEwayBillAttachment] = useState(null);

  const [tripStartMeterReadingAttachment, setTripStartMeterReadingAttachment] =
    useState({
      attachment_tag: "trip_start_meter_reading_attachment",
      attachment_type: "",
      attachment_file: null,
    });

  const [customerInvoiceAttachment, setCustomerInvoiceAttachment] = useState();
  const [customerInvoiceNo, setCustomerInvoiceNo] = useState("");
  const [currentSourceId, setCurrentSourceId] = useState(null);
  const [tripStatus, setTripStatus] = useState("created");
  const [tripDistance, setTripDistance] = useState("");
  const [tripDistanceError, setTripDistanceError] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("0");
  const [errSourceFinal, setErrSourceFinal] = useState("");
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [errDriverFinal, setErrDriverFinal] = useState("");
  const [valueOfGoods, setValueOfGoods] = useState("");
  const [invoiceValueError, setInvoiceValueError] = useState("");
  const [invoiceButtonPressed, setInvoiceButtonPressed] = useState(false);
  const [totalQuantityForPBC, setTotalQuantityForPBC] = useState([]);
  const [pbcCostPurchase, setPbcCostPurchase] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedConsignee, setSelectedConsignee] = useState({});
  const [selectedBillToParty, setSelectedBillToParty] = useState({});

  const [driverLoadingButton, setDriverLoadingButton] = useState(false);
  const [customerLoadingButton, setCustomerLoadingButton] = useState(false);
  const [errCreateTripFinal, setErrCreateTripFinal] = useState("");
  const [ewayBillNumber, setEwayBillNumber] = useState("");
  const [ewayBillNumberError, setEwayBillNumberError] = useState("");
  const [qunatityInTonnes, setQuantityInTonnes] = useState("");
  const [quantityInTonnesError, setQuantityInTonnesError] = useState("");
  const [operationsSpoc, setOperationsSpoc] = useState("");

  const [bhadaChiti, setBhadaChiti] = useState();
  const [biltyCopy, setBiltyCopy] = useState();

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [ewayBillFrom, setEwayBillFrom] = useState("");
  const [ewayBillTo, setEwayBillTo] = useState("");
  const [tripStartDate, setTripStartDate] = useState("");

  const [customerInvoiceDate, setCustomerInvoiceDate] = useState("");
  const [transporterInvoiceNumber, setTransporterInvoiceNumber] = useState("");
  const [transporterInvoiceNumberError, setTransporterInvoiceNumberError] =
    useState("");
  const [errInTransporterAdvance, setErrInTransporterAdvance] = useState("");
  const [tripCost, setTripCost] = useState([
    {
      cost_type: "advance",
      cost_amount: "",
    },
    {
      cost_type: "trip_cost",
      cost_amount: "",
    },
    {
      cost_type: "loading_labour_charges",
      cost_amount: "",
    },
    {
      cost_type: "loading_detention_charges",
      cost_amount: "",
    },
  ]);
  const [tripCostDuplicate, setTripCostDuplicate] = useState([]);

  const [errDriverDetails, setErrDriverDetails] = useState({
    errFirstName: "",
    errMobileNumber: "",
    errPanNumber: "",
    errDLNumber: "",
    errPanDocs: "",
    errDLDocs: "",
  });

  const [errCustomerDetails, setCustomerDetails] = useState({
    errName: "",
    errLogo: "",
  });
  const [driverListOptions, setDriverListOptions] = useState([]);
  const [transporterListOption, setTransporterListOption] = useState([]);
  const [tripIdEdit, setTripIdEdit] = useState(null);
  const [errPincodeNotServiceable, setErrPincodeNotServiceable] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [loadingDataForEdit, setLoadingDataForEdit] = useState(true);
  const [entityType, setEntityType] = useState("0");
  const [loadUnitList, setLoadUnitList] = useState([]);
  const [tripType, setTripType] = useState(removedStringForTripType(trip_type));
  const [currentTab, setCurrentTab] = useState(null);
  const [referenceNumberManual, setReferenceNumberManual] = useState("");
  const [lrNumberError, setLrNumberError] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [consigneeSearchValue, setConsigneeSearchValue] = useState("");
  const [billToSearchValue, setBillToSearchValue] = useState("");
  const [teamsList, setTeamsList] = useState([]);
  const [teamsSearchTerm, setTeamsSearchTerm] = useState("");

  const [paymentStatusList, setPaymentStatusList] = useState([
    "unpaid",
    "partially_paid",
  ]);

  const tripDetailsFromRedux = useSelector((state) => state.trip.tripDetails);

  const driverDetailsFromRedux = useSelector(
    (state) => state.trip.driverDetails
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "60vh",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 4,
  };

  const resetFormDriver = () => {
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
    });
    setDrivingPhoto(null);
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
  };

  const minDate = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const getTripDetailsFromApi = async (tripId) => {
    const response = await getFtlTripDetailsFromId(tripId);
    console.log(response, "response in get trip by id");
    if (response.status === 400) {
      setIsSomethingWentWrongError(true);
    } else {
      dispatch(updateTripDetails(response));
      dispatch(updateDriverDetails(response.driver));
      setSecondaryDriverId(response.secondary_driver_id);
    }
  };

  useEffect(() => {
    const filterdDriver = driverListOptions.filter(
      (item) => item.id === secondaryDriverId
    );
    setSecondaryDriverDetails({
      first_name: filterdDriver[0]?.first_name,
      last_name: filterdDriver[0]?.last_name,
      mobile_number: filterdDriver[0]?.mobile_number,
      pan_number: filterdDriver[0]?.pan_number,
      driving_licence_number: filterdDriver[0]?.driving_licence_number,
      alternate_mobile_number: filterdDriver[0]?.alternate_mobile_number,
    });
  }, [driverListOptions, secondaryDriverId]);

  useEffect(() => {
    let operableData = tripCost.map((ele) => {
      return {
        cost_type: ele.cost_type,
        cost_amount: ele.cost_amount,
      };
    });
    setTripCostDuplicate(operableData);
  }, [tripCost]);

  useEffect(() => {
    if (tripId) {
      getTripDetailsFromApi(tripId);
    }
  }, [tripId]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();

    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    let attachmentArrayTemp = [...attachmentListArray];

    if (index == 0 && loadSource != "trip_invoice") {
      attachmentArrayTemp[0]["attachment_tag"] = "bilty_copy";
    }
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
  };

  const handlePress = (e) => {
    setCurrentCustomerField(e.target.name);
  };

  const getTripSettingsByCustomer = async (id, tripType) => {
    const response = await getSettingsListByCustomerId(parseInt(id), tripType);
    if (response) {
      setTripSettingsList(response.whitelisted_consignors_list);
    }
  };

  useEffect(() => {
    if (consignorId && removedStringForTripType(trip_type)) {
      getTripSettingsByCustomer(
        consignorId,
        removedStringForTripType(trip_type)
      );
    }
  }, [consignorId, removedStringForTripType(trip_type)]);

  const getWareHouseListApi = async () => {
    var wareHouseList = await getWarehouseList(selectedCustomer.id, 1);
    setWareHouseList(wareHouseList.warehouses_list);
  };

  const vertical = "bottom";
  const horizontal = "right";

  useEffect(() => {
    if (!(vehiclePayoutPeriod == "monthly_wise")) {
      let tempperKm =
        (tripCost.length > 0 ? tripCost[1].cost_amount : 0) / tripDistance;
      setPerKmRate(tempperKm.toFixed(2));
    }
  }, [tripDistance, tripCost]);

  useEffect(() => {
    if (Object.keys(selectedCustomer).length > 0) {
      getWareHouseListApi();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (editMode == true && !transporterNameId && !driverId) {
      handleOpenFullPageLoad();
    }

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      if (editMode == true) {
        handleCloseFullPageLoad();
      }
      setCurrentCustomerField("consignor");
      setConsignorId(tripDetailsFromRedux.customer_id);
      setDestinationAddressList(tripDetailsFromRedux.destination);
      setSourceAddressList(tripDetailsFromRedux.source);
      setEditMode(true);
      setTripIdEdit(tripDetailsFromRedux.id);
      setSelectedCustomer(tripDetailsFromRedux.customers_details);
      setFacilitatedBy(
        tripDetailsFromRedux.trip_additional_data.facilitated_by
      );
      console.log(tripDetailsFromRedux.trip_additional_data.facilitated_by);
      setSelectedConsignee(
        tripDetailsFromRedux.consignee_details
          ? tripDetailsFromRedux.consignee_details
          : {}
      );
      setSelectedBillToParty(tripDetailsFromRedux.bill_to_party_details);
      setLoadSource(tripDetailsFromRedux.load_source);
      setPaymentStatus(tripDetailsFromRedux.payments_status);
      setPaymentTerms(tripDetailsFromRedux.payment_terms);

      setTripType(
        tripDetailsFromRedux.trip_type ? tripDetailsFromRedux.trip_type : ""
      );

      setPerKmRate(tripDetailsFromRedux.per_km_rate);
      setValueOfGoods(tripDetailsFromRedux.value_of_goods);
      setExpectedDeliveryDate(tripDetailsFromRedux.expected_delivery_date);
      setQuantityInTonnes(tripDetailsFromRedux.quantity_in_tonnes);
      setOperationsSpoc(
        tripDetailsFromRedux.trip_additional_data.operations_spoc
      );
      setCustomerInvoiceNo(tripDetailsFromRedux.customer_invoice_number);
      setCustomerInvoiceDate(tripDetailsFromRedux.customer_invoice_date);
      setTransporterInvoiceNumber(
        tripDetailsFromRedux?.trip_additional_data.transporter_invoice_number
      );
      if (tripDetailsFromRedux.trip_eway_bill) {
        setEwayBillNumber(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_number
        );
        setEwayBillFrom(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_from
        );
        setEwayBillTo(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_till
        );
      }
      setVehiclePayoutPeriod(tripDetailsFromRedux.vehicle_payout_period);
      setTripStartMeterReadingAttachment({
        ...tripStartMeterReadingAttachment,
        attachment_file: "sample-text",
      });
      setTripStartMeterReading(tripDetailsFromRedux.trip_start_meter_reading);
      setTripStartDate(tripDetailsFromRedux.trip_start_date);
      if (tripDetailsFromRedux.trip_cost.length > 1) {
        console.log("trip_cost if", tripDetailsFromRedux.trip_cost);
        //
        let tripCostTemp = [
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ];
        console.log(
          "trip_cost 1",
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          }))
        );
        if (tripCostTemp[0]["cost_type"] == "trip_cost") {
          tripCostTemp.reverse();
        }

        console.log("trip_cost if last", tripCost);
        // setTripCost([...tripDetailsFromRedux.trip_cost]);
        setTripCost([...tripCostTemp]);
      } else if (tripDetailsFromRedux.trip_cost.length == 1) {
        console.log("trip_cost else", tripDetailsFromRedux.trip_cost);
        setTripCost([
          {
            cost_type: "advance",
            cost_amount: "",
          },
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ]);
      } else {
        console.log("trip_cost else", tripDetailsFromRedux.trip_cost);
        setTripCost([...tripDetailsFromRedux.trip_cost]);
      }

      setTotalQty(tripDetailsFromRedux.quantity);
      setDriverDetails(driverDetailsFromRedux);
      setDriverId(driverDetailsFromRedux.id);
      setLoadUnit(
        loadUnitList.length > 0
          ? loadUnitList.filter(
              (item) => item.id == tripDetailsFromRedux.load_unit_id
            )[0].unit_code
          : null
      );
      setConsigneeSearchValue(tripDetailsFromRedux.consignee);
      setVehicleNumber(tripDetailsFromRedux.vehicle_number);
      setReferenceNumberManual(tripDetailsFromRedux.reference_number_for_trip);
      setEntityType(tripDetailsFromRedux.entity_type);
      setSelectedTransporterName(tripDetailsFromRedux.transporter_name);
      setTransporterNameId(tripDetailsFromRedux.transporter_id);
      setTripDistance(tripDetailsFromRedux.estimated_kms);
      if (tripDetailsFromRedux.load_source == "trip_purchase_order") {
        setPurchaseOrderId(tripDetailsFromRedux.reference_number);
        //
      } else if (tripDetailsFromRedux.load_source == "trip_invoice") {
        setInvoiceId(tripDetailsFromRedux.reference_number);
      }
      setCustomerInvoiceNo(tripDetailsFromRedux.customer_invoice_number);
      setCustomerInvoiceDate(tripDetailsFromRedux.customer_invoice_date);
      setTransporterInvoiceNumber(
        tripDetailsFromRedux?.trip_additional_data.transporter_invoice_number
      );
      setExpectedDeliveryDate(tripDetailsFromRedux.expected_delivery_date);
      setQuantityInTonnes(tripDetailsFromRedux.quantity_in_tonnes);
    }
  }, [tripDetailsFromRedux, driverDetailsFromRedux, loadUnitList]);

  useEffect(() => {
    toggleDrawer("right", true);
  }, [currentTab]);

  const handleDriverSelect = (e) => {
    setDriverDetails(e);
    setDriverId(e.id);
    setErrDriverFinal("");
  };

  const handleSecondaryDriverSelect = (e) => {
    setSecondaryDriverDetails(e);
    setSecondaryDriverId(e.id);
    setErrDriverFinal("");
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleAddAttachment = (e) => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    attachmentTemp.push({
      attachment_tag: "1",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
    e.preventDefault();
  };

  const handleGetCustomerSearch = async (searchTerm) => {
    const response = await GetCustomerSearchList(searchTerm);

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      setCustomerList(
        response.customers_list
          .filter((item) => item.name !== "faarms")
          .filter((item) => item.name !== "FAARMS")
          .filter((item) => item.name !== "Faarms")
      );
    } else {
      setCustomerList(
        response.customers_list
          .filter((item) => item.name !== "faarms")
          .filter((item) => item.name !== "FAARMS")
          .filter((item) => item.name !== "Faarms")
      );
    }
  };

  const [handleInputErr, setHandleInputErr] = useState({
    operationSPOC: "",
  });

  const handleInputField = (e) => {
    const { name, value } = e.target;
    if (name === "operation_spoc") {
      if (faarmsAndConnectIndiaEmailRegex.test(value)) {
        setHandleInputErr({
          ...handleInputErr,
          operationSPOC: "",
        });
      } else {
        setHandleInputErr({
          ...handleInputErr,
          operationSPOC:
            "Only faarms.com, connectindia.com and bscxpress.com domain are allowed",
        });
      }
    }
  };

  useEffect(() => {
    if (customerSearchValue) {
      handleGetCustomerSearch(customerSearchValue);
    } else {
      setCustomerList([]);
    }
  }, [customerSearchValue.length > 2]);

  useEffect(() => {
    if (billToSearchValue) {
      handleGetCustomerSearch(billToSearchValue);
    } else {
      // handleGetCustomerSearch("");
      setCustomerList([]);
    }
  }, [billToSearchValue.length > 2]);

  const handleChangeTripMeterReadingAttachment = async (e) => {
    const newFile = await saveBlob(e.target.files[0]);
    setTripStartMeterReadingAttachment({
      ...tripStartMeterReadingAttachment,
      attachment_file: newFile,
    });
  };

  const handleChangeCustomerInvoiceAttachment = async (e) => {
    const newFile = await saveBlob(e.target.files[0]);
    setCustomerInvoiceAttachment(newFile);
  };

  let filtredAllMandatory = tripSettingsList.filter(
    (data) => data.behaviour == "mandatory"
  );
  let newArray = filtredAllMandatory.map((data) => data.fields);
  const mandatoryFields = newArray.flat();

  let filtredAllOptional = tripSettingsList.filter(
    (data) => data.behaviour == "optional"
  );
  let newArrayOptional = filtredAllOptional.map((data) => data.fields);
  const optionalFields = newArrayOptional.flat();

  useEffect(() => {
    if (mandatoryFields.length > 0) {
      setChecked(false);
    }
  }, [mandatoryFields]);

  useEffect(() => {
    return () => {
      dispatch(updateTripDetails({}));
      dispatch(updateDriverDetails({}));
    };
  }, []);

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);

      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalTemp).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else if (totalQty > 0) {
      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalQty).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else {
      setPbcCostPurchase("0.0");
    }
  }, [totalQuantityForPBC, tripCost, totalQty]);

  const checkValidationAll = async () => {
    if (!driverId) {
      setErrDriverFinal("Please enter driver details");
    }

    const tripTypeValid = tripType;

    let tripAdvanceValid = false;
    if (vehiclePayoutPeriod == "monthly_wise") {
      tripAdvanceValid = true;
    } else {
      tripAdvanceValid =
        tripCost[0].cost_amount === ""
          ? true
          : parseInt(tripCost[0].cost_amount) < 0
          ? false
          : parseInt(tripCost[0].cost_amount) <=
            parseInt(tripCost[1].cost_amount)
          ? true
          : false;
    }

    let isEwayBillValid = true;

    if (mandatoryFields.length > 0) {
      isEwayBillValid =
        mandatoryFields.length > 0
          ? ewayBillNumber && ewayBillFrom && ewayBillTo && editMode
            ? true
            : ewayBillAttachment && ewayBillFrom <= ewayBillTo
          : false;

      // ewayBillFrom &&
      // ewayBillNumber &&
      // ewayBillTo &&
      // ewayBillFrom <= ewayBillTo;
    } else {
      isEwayBillValid = true;
    }

    const fileSizeValid = tripStartMeterReadingAttachment.attachment_file
      ? tripStartMeterReadingAttachment.attachment_file.size <
        process.env.REACT_APP_MAX_FILE_SIZE
        ? true
        : false
      : false;

    const isCustomerValid = Object.keys(selectedCustomer).length > 0;
    // const isConsigneeValid =
    //   Object.keys(selectedCustomer).length > 0
    //     ? selectedCustomer?.name?.toLowerCase().includes("faarms")
    //       ? true
    //       : Object.keys(selectedConsignee).length > 0
    //     : true;

    const isBillToPartyValid =
      Object.keys(selectedCustomer).length > 0
        ? selectedCustomer?.name?.toLowerCase().includes("faarms")
          ? true
          : Object.keys(selectedBillToParty).length > 0
        : true;

    //

    return (
      tripTypeValid &&
      driverId &&
      transporterNameId &&
      driverDetails.mobile_number.length === 10 &&
      tripAdvanceValid &&
      tripStartMeterReading &&
      tripStartMeterReadingAttachment.attachment_file &&
      perKmRate &&
      (!fileSizeValid && editMode ? true : fileSizeValid) &&
      valueOfGoods &&
      referenceNumberManual &&
      isEwayBillValid &&
      // isConsigneeValid &&
      isCustomerValid &&
      isBillToPartyValid
    );
  };

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);
      setTotalQty(totalTemp);
    } else {
      setTotalQty("");
    }
  }, [totalQuantityForPBC]);

  const handleAddDriver = () => {
    toggleDrawer("right", true);
    setErrDriverFinal("");
    setCurrentTab("driver");
  };

  const [errorInaddressSource, setErrorInaddressSource] = useState("");
  const [errorInaddressDestination, setErrorInaddressDestination] =
    useState("");

  //
  const handleCreateTrip = async () => {
    const isTripValid = await TripSchema.isValid(TripFormData);

    //
    if (isTripValid && (await checkValidationAll())) {
      if (
        attachmentListArray
          .map((item) => {
            if (item.attachment_file) {
              if (
                item.attachment_file.size > process.env.REACT_APP_MAX_FILE_SIZE
              ) {
                return "greater";
              }
            }
          })
          .indexOf("greater") > -1
      ) {
        return;
      }
      setLoadingButton(true);

      if (!loadingButton) {
        if (editMode) {
          if (
            newSourceAddressForEdit.unchanged.length == 0 &&
            newSourceAddressForEdit.new.length == 0
          ) {
            setErrorInaddressSource("Please Enter Source.");
            setLoadingButton(false);
          } else if (
            newDestinationAddressForEdit.unchanged.length == 0 &&
            newDestinationAddressForEdit.new.length == 0
          ) {
            setErrorInaddressDestination("Please Enter Destination.");
            setLoadingButton(false);
          } else {
            const response = await UpdateTripPut(
              newSourceAddressForEdit,
              newDestinationAddressForEdit,
              vehicleNumber,
              transporterNameId,
              tripDistance,
              tripIdEdit,
              selectedCustomer.id,
              driverId,
              secondaryDriverId,
              paymentTerms,
              totalQty,
              tripCostDuplicate,
              operationsSpoc,
              loadSource,
              perKmRate,
              tripStartMeterReading,
              valueOfGoods,
              ewayBillNumber,
              ewayBillFrom,
              ewayBillTo,
              ewayBillAttachment,
              tripType,
              consigneeSearchValue,
              selectedBillToParty?.id
                ? selectedBillToParty?.id
                : selectedCustomer?.id,
              customerInvoiceAttachment,
              customerInvoiceNo,
              qunatityInTonnes,
              bhadaChiti,
              biltyCopy,
              tripStartMeterReadingAttachment || editMode,
              expectedDeliveryDate,
              tripStartDate,
              transporterInvoiceNumber,
              referenceNumberManual,
              facilitatedBy
            );
            //
            if (response?.status === 400) {
              setLoadingButton(false);
              setErrCreateTripFinal(response.data.detail);
            } else if (response?.status === 200) {
              localStorage.setItem("FlagForApiCall", false);
              // localStorage.setItem("reloadPage", true);
              // localStorage.removeItem("localStorageTripList");
              if (localStorage.getItem("createTripSource") == "/ftl-trips") {
                history.push("/ftl-trips");
              } else {
                history.push("/faarms-trip");
              }
              setLoadingButton(false);
            } else if (response.status == 422) {
              if (response.data.detail.length > 0) {
                setErrCreateTripFinal(
                  `Error 422 : ${response.data.detail[0].msg}`
                );
              }
              setLoadingButton(false);
            } else {
              setLoadingButton(false);
              setErrCreateTripFinal(`ERROR : ${response?.status}`);
            }
          }
          //
        } else {
          // approxCostDetails["cost_type"] = "approx_trip_cost";
          const response = await CreateTripPost(
            sourceAddressList,
            destinationAddressList,
            driverDetails,
            secondaryDriverDetails,
            vehicleNumber,
            transporterNameId,
            driverId,
            secondaryDriverId,
            driverDetails.mobile_number,
            loadSource,
            loadUnit,
            loadSource == "trip_invoice"
              ? invoiceId.length > 0
                ? invoiceId.map((item) => ({
                    reference_number: item[0],
                    reference_id: item[1],
                  }))
                : []
              : loadSource == "trip_purchase_order"
              ? purchaseOrderId.length > 0
                ? purchaseOrderId.map((item) => ({
                    reference_number: item[0],
                    reference_id: item[1],
                  }))
                : []
              : referenceNumberManual.length > 0
              ? referenceNumberManual.split(",").map((item) => {
                  if (item) {
                    return { reference_id: item, reference_number: item };
                  }
                })
              : [],

            tripStatus,
            tripDistance,
            paymentStatus,
            tripType === "middle_mile"
              ? "to_be_billed"
              : tripType === "last_mile"
              ? "to_payee"
              : paymentTerms,
            tripCost,
            totalQty,
            selectedCustomer.id,
            tripStartMeterReading,
            tripStartMeterReadingAttachment,
            perKmRate,
            !selectedCustomer.name.toLowerCase().includes("faarms")
              ? "others"
              : entityType,
            valueOfGoods,
            ewayBillNumber,
            ewayBillFrom,
            ewayBillTo,
            ewayBillAttachment,
            tripType,
            consigneeSearchValue,
            selectedCustomer,
            selectedBillToParty?.id
              ? selectedBillToParty?.id
              : selectedCustomer?.id,
            customerInvoiceAttachment,
            expectedDeliveryDate,
            customerInvoiceNo,
            qunatityInTonnes,
            bhadaChiti,
            attachmentListArray,
            biltyCopy,
            operationsSpoc,
            tripStartDate,
            customerInvoiceDate,
            transporterInvoiceNumber,
            facilitatedBy
          );
          if (response == "failed") {
            setLoadingButton(false);
          } else if (response.status == 201) {
            localStorage.setItem("FlagForApiCall", false);
            // localStorage.setItem("reloadPage", true);
            // localStorage.removeItem("localStorageTripList");

            // const attachmentResponse = TripAttachmentPost(
            //   "kk",
            //   response.data.id,
            //   "lso",
            //   "lsp",
            //   "start_trip",
            //   attachmentListArray
            // );
            //
            // const resolved = await Promise.all(attachmentResponse);
            if (localStorage.getItem("createTripSource") == "/ftl-trips") {
              history.push("/ftl-trips");
            } else {
              history.push("/faarms-trip");
            }

            // const url = "/faarms-trip";
            // window.open(url, "_blank");
            setLoadingButton(false);
          } else if (response.status == 401) {
            setPermission("Not authorized to create trip");
            setLoadingButton(false);
          } else if (response.status == 400) {
            setErrCreateTripFinal(response.data.detail);
            setLoadingButton(false);
          } else if (response.status == 422) {
            if (response.data.detail.length > 0) {
              setErrCreateTripFinal(
                `Error 422 : ${response.data.detail[0].msg}`
              );
            }

            setLoadingButton(false);
          } else {
            setErrCreateTripFinal(`Error : ${response.status}`);
            setLoadingButton(false);
          }
        }
      }
    } else {
      setErrTrip({
        errLoadSource: "Please select source",
        errReferenceId: "Please select reference Id",
        errVehicleNumber: "Please select vehicle",
        errTransporter: "Please select Transporter",
        errLoadUnit: "Please select load unit",
        errTripDistance: "Please enter valid trip distance",
        errPaymentTerms: "Please enter valid payment terms",
        errTotalCost: "Please enter valid cost",
        // errAdvanceCost:
        //   " Advance amount should not be greater than cost amount",
        errTotalQty: "Please enter valid quantity",
        errCustomer: "Please select valid consignor",
        errConsignee: "Please select valid consignee",
        errBillToParty: "Please select valid bill to party",
        errTripStartMeterReading: "Please enter valid meter reading",
        errTripStartMeterReadingAttachment: "Please select valid attachment",
        errPerKmRate: "Please enter valid per Km Rate",
        errValueOfGoods: "Please enter valid Invoice Value",
        errCustomerBilledAmount: "please enter billed amount",
        errCustomerAdvanceAmount: "please enter Advance amount",
        // errNegativeRevenueTripApprovalEmail: "Please enter valid email id",
        // errNegativeRevenueTripApprovalAttachment:
        //   "Please select valid attachment",
        errTripType: "Please select valid trip type",
        errLrNumber: "Please enter LR Number",
        errEwayNumber: "Please enter valid E-way number",
        errEwayFrom: "Please enter valid E-way from date",
        errEwayTo: "Please enter valid E-way till date",
        quantity_in_tonnes: "Please enter valide quantity",
        no_of_pieces: "Please enter valid No. of pieces",
        customer_invoice: "Please enter valid customer invoice no.",
        operationSPOC: "Please enter valid Operations SPOC Email.",
        customerInvoiceDate: "Plese enter valid customer invoice date.",

        // errPaymentStatus: "Please select payment status",
      });
      // setErrEwayBill({
      //   errEwayNumber: "Please enter valid E-way number",
      //   errEwayFrom: "Please enter valid E-way from date",
      //   errEwayTo: "Please enter valid E-way till date",
      // });
    }
  };

  const handleGetAddressSource = async (pincode) => {
    //
    const response = await GetAddressFromPincode(pincode);

    if (response) {
      setAddressDetails({
        district: response.district,
        sub_district: response.district,
        state: response.state,
        postoffice: response.postoffice,
        type: "source",
      });
      // const source = sourceDetails;
      // source[index]["district"] = response.district;
      // source[index]["sub_district"] = response.sub_district;
      // source[index]["state"] = response.state;
      // source[index]["postoffice"] = response.office[0].name;
      // source[index]["postcode"] = response.pincode;

      setSourceVillagesList(response.area);
    } else {
      setErrorAddressPincode("This Pincode not serviceable");
      // setErrPincodeNotServiceable("This Pincode not serviceable");
    }
  };

  const handleGoBack = () => {
    localStorage.setItem("FlagForApiCall", false);
    // localStorage.setItem("doNotReloadPage", true);
    if (localStorage.getItem("createTripSource") == "/ftl-trips") {
      history.push("/ftl-trips");
    } else {
      history.push("/faarms-trip");
    }
    // history.push("/faarms-trip");
  };

  const DriverSchema = yup.object().shape({
    first_name: yup.string().required(),
    mobile_number: yup.number().min(6000000000).max(9999999999).required(),

    driving_licence_number: yup.string().min(6).required(),
    dl_docs: yup.mixed().required(),
  });
  const TripSchema = yup.object().shape({
    trip_source: yup.string().min(2),
    vehicle_number: yup.string().min(2).required(),
    trip_distance: yup.number().min(1),
    load_unit: yup.string().min(2).required(),
    payment_terms: yup.string().min(2).required(),
    trip_cost: yup.number().min(10).required(),
  });

  const TripFormData = {
    trip_source: loadSource,
    load_unit: "ton",
    vehicle_number: vehicleNumber,
    trip_distance: tripDistance,
    payment_terms:
      vehiclePayoutPeriod == "monthly_wise"
        ? "none"
        : tripType === "middle_mile"
        ? "to_be_billed"
        : tripType === "last_mile"
        ? "to_payee"
        : paymentTerms,
    trip_cost:
      vehiclePayoutPeriod == "monthly_wise"
        ? "1000"
        : tripCost.length > 1
        ? tripCost[1].cost_amount
        : null,
  };

  const invoiceQuantityUpdate = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQty = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    invoiceQtyTemp.push(totalQty);
    setInvoiceTotalQuantity(invoiceQtyTemp);
    setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
  };

  const invoiceQuantityRemove = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQtyTemp = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    //remove from array
    const indexTemp = invoiceQtyTemp.indexOf(totalQtyTemp);
    if (indexTemp > -1) {
      invoiceQtyTemp.splice(indexTemp, 1);
    }

    //remove from array
    // invoiceQtyTemp.push(totalQtyTemp);
    setInvoiceTotalQuantity(invoiceQtyTemp);

    if (invoiceQtyTemp.length > 0) {
      setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
    } else {
      setTotalQty(0);
    }
  };

  const sourceFormData = {
    concern_person_name: sourceDetails.concern_person_name,
    concern_person_mobile_number: sourceDetails.concern_person_mobile_number,
    postcode: sourceDetails.postcode,
    village: sourceDetails.village,
  };

  const driverFormData = {
    first_name: driverDetails.first_name,
    mobile_number: driverDetails.mobile_number,
    // pan_number: driverDetails.pan_number,
    driving_licence_number: driverDetails.driving_licence_number,
    // pan_docs: driverPanImage,
    dl_docs: drivingPhoto,
  };

  const handleDrivingPhoto = (event) => {
    setDrivingPhoto(event.target.files[0]);
  };

  const handleCustomerLogo = (event) => {
    setAddCustomerData({ ...addCustomerData, logo: event.target.files[0] });
  };

  const handleDriverSave = async () => {
    const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isDLSizeValid = drivingPhoto
      ? drivingPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;

    if (isDriverValid && isDLSizeValid) {
      setDriverLoadingButton(true);

      const driverPostResponse = await CreateDriverPost(
        driverDetails.first_name,
        driverDetails.last_name,
        driverDetails.mobile_number,
        driverDetails.driving_licence_number,
        driverDetails.pan_number,
        driverPanImage,
        drivingPhoto,
        driverDetails.alternate_mobile_number
      );
      if (driverPostResponse.status === 200) {
        setDriverId(driverPostResponse.data.id);
        toggleDrawer("right", false);
        setDriverLoadingButton(false);
      } else if (driverPostResponse.status === 400) {
        setDriverLoadingButton(false);
        setErrTotalDriver(driverPostResponse.data.detail);
      } else {
        setDriverLoadingButton(false);
        setErrTotalDriver(`ERROR : ${driverPostResponse.status}`);
      }
      //
    } else {
      setErrDriverDetails({
        errFirstName: "Please enter valid first name",
        errMobileNumber: "Please enter valid mobile number",
        errPanNumber: "Please enter valid PAN number",
        errDLNumber: "Please enter valid DL number",
        errPanDocs: "Please upload valid PAN image/pdf",
        errDLDocs: "Please upload valid DL image/pdf",
      });
    }
  };

  const handleCustomerSave = async () => {
    // const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isLogoSizeValid = addCustomerData
      ? addCustomerData.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;
    //
    if (addCustomerData?.name && addCustomerData?.logo && isLogoSizeValid) {
      setCustomerLoadingButton(true);

      const customerPostResponse = await customersPost(
        addCustomerData?.name,
        addCustomerData?.logo,
        "image",
        addCustomerData?.description
      );
      if (customerPostResponse === 200) {
        handleCloseCustomerDrawer();
        setAddCustomerData({});
        // toggleDrawer("right", false);

        setCustomerLoadingButton(false);
      } else if (customerPostResponse?.status === 400) {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(customerPostResponse.data.detail);
      } else {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(`ERROR : ${customerPostResponse?.status}`);
      }
      //
    } else {
      setCustomerDetails({
        errName: "Please enter valid name",
        errLogo: "Please select valid logo file",
      });
    }
  };

  const handleAddSource = (e) => {
    e.stopPropagation();
    setSourceDetails([
      ...sourceDetails,
      {
        concern_person_name: "",
        concern_person_mobile_number: "",
        postcode: "",
        type: "source",
        village: "0",
        postoffice: "",
        sub_district: "",
        district: "",
        state: "",
        landmark: "",
        latitude: "",
        longitude: "",
      },
    ]);
  };

  const handleFetchPurchaseOrders = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetPurchaseOrders(searchValue);
    setSearchInvoiceLoading(false);

    setSearchResultsList(
      response.purchaseorders.map((item) => {
        return {
          value: [item.purchaseorder_number, item.purchaseorder_id],
          label: item.purchaseorder_number,
          quantity: item.total_ordered_quantity,
        };
      })
    );
  };

  const handleFetchVehicles = async (searchValue, source) => {
    const response = await GetVehicles(searchValue, source);

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      return [
        {
          value: tripDetailsFromRedux.vehicle_number,
          label: tripDetailsFromRedux.vehicle_number,
        },
        ...response.vehicle_list.map((item) => {
          const dateToday = moment().format("YYYY-MM-DD");

          let startDate = moment(dateToday, "YYYY-MM-DD");
          let expireDateRC = moment(
            item.registration_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDateInsurance = moment(
            item.insurance_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
          let expireDatePollution = moment(
            item.pollution_control_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let insuranceExpires = expireDateInsurance.diff(startDate, "days");
          let permitExpires = expireDatePermit.diff(startDate, "days");
          let pollutionExpires = expireDatePollution.diff(startDate, "days");
          let rcExpires = expireDateRC.diff(startDate, "days");
          return {
            value: item.vehicle_number,
            payout_period: item.payout_period,
            status: item.status,
            vehicle_manufacturer_name: item.vehicle_manufacturer_name,
            vehicle_type: item.vehicle_model_list.vehicle_type,
            extra_per_km_rate: item.extra_per_km_rate,
            insuranceExpires: expireDateInsurance.diff(startDate, "days"),
            permitExpires: expireDatePermit.diff(startDate, "days"),
            pollutionExpires: expireDatePollution.diff(startDate, "days"),
            rcExpires: expireDateRC.diff(startDate, "days"),
            label: (
              <div>
                <div>{item.vehicle_number}</div>
                <div>{item.vehicle_manufacturer_name}</div>
              </div>
            ),
          };
        }),
      ];
    }

    return response.vehicle_list.map((item) => {
      const dateToday = moment().format("YYYY-MM-DD");

      let startDate = moment(dateToday, "YYYY-MM-DD");
      let expireDateRC = moment(
        item.registration_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDateInsurance = moment(
        item.insurance_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
      let expireDatePollution = moment(
        item.pollution_control_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let insuranceExpires = expireDateInsurance.diff(startDate, "days");
      let permitExpires = expireDatePermit.diff(startDate, "days");
      let pollutionExpires = expireDatePollution.diff(startDate, "days");
      let rcExpires = expireDateRC.diff(startDate, "days");
      return {
        value: item.vehicle_number,
        payout_period: item.payout_period,
        status: item.status,
        vehicle_manufacturer_name: item.vehicle_manufacturer_name,
        vehicle_type: item.vehicle_model_list.vehicle_type,
        extra_per_km_rate: item.extra_per_km_rate,
        insuranceExpires: expireDateInsurance.diff(startDate, "days"),
        permitExpires: expireDatePermit.diff(startDate, "days"),
        pollutionExpires: expireDatePollution.diff(startDate, "days"),
        rcExpires: expireDateRC.diff(startDate, "days"),
        label: (
          <Create_trip_vehicle_single
            style={{ display: "flex", width: "100%" }}
            // className="create-trip-vehicle-single"
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="create-trip-vehicle-number-text">
                    {item.vehicle_number}
                  </span>
                </div>
                <div>
                  <span className="create-trip-sub-text">
                    {item.vehicle_manufacturer_name} (
                    {item.vehicle_model_list.vehicle_type})
                  </span>
                </div>
                {/* <div>
                  {item.payout_period &&
                  item.payout_period == "monthly_wise" ? (
                    <div className="create-trip-monthly-container">
                      <img
                        src={MONTHLYICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Monthly</h4>
                    </div>
                  ) : (
                    <div className="create-trip-monthly-container">
                      <img
                        src={TRIPWISEICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Tripwise</h4>
                    </div>
                  )}
                </div> */}
                <div
                  style={{
                    marginLeft: isMobile ? "10px" : "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    color: "white",
                    padding: "1px 4px",
                    background:
                      item.status === "available"
                        ? colorPalette.primaryColor
                        : item.status === "blocked"
                        ? "#FF6666"
                        : item.status === "in_transit"
                        ? "#E5B35D"
                        : "#65c368",
                    borderTopLeftRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }}
                >
                  {item.status ? capitalizeLetter(item.status) : "--"}
                </div>
              </div>

              <div>
                <span className="create-trip-sub-text">
                  {item.transporters_details}{" "}
                </span>
              </div>
              <div
                className="vehicle-expiry-check-container"
                style={{ marginTop: "15px" }}
              >
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={rcExpires >= 0 ? TICKICON : WRONGICON}
                      className="create-trip-right-wrong-img"
                      alt=""
                    />
                  </div>
                  <div className="vehicle-expiry-text"> RC</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={insuranceExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Insurance</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={permitExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Permit</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={pollutionExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Pollution</div>
                </div>
              </div>
            </div>
          </Create_trip_vehicle_single>
        ),
      };
    });
  };
  function isNumber(value) {
    return /^[0-9]*$/.test(value);
  }
  const handleFetchTransporter = async (values) => {
    const integerValue = isNumber(values);

    try {
      const productResponse = await axiosInterceptor.get(
        `${apiUrl.onboardingBaseUrl}/v3/transporter?transporter_code=${
          integerValue == false ? values.trim() : ""
        }&mobile=${integerValue ? values : ""}&status=approved&is_active=true`
      );

      const optionsList = productResponse.data.transporter_list.map((item) => {
        return {
          value: item.id,
          transporter_code: item.transporter_code,
          name: item.name,
          mobile: item.mobile,
          // district: item.address.district,
          // state: item.address.state,

          label: (
            <div
              style={{ display: "flex" }}
              className="create-trip-vehicle-single"
            >
              <div>
                <div>
                  <div>
                    <span className="create-trip-transporter-number-text">
                      {item.transporter_code}
                    </span>
                  </div>
                </div>

                <div>
                  <span className="create-trip-sub-text">
                    <PersonOutlineOutlinedIcon className="trip_transporter_icons" />
                    {item.name}
                  </span>
                </div>
                <div>
                  <span className="create-trip-sub-text">
                    {" "}
                    <PhoneAndroidOutlinedIcon className="trip_transporter_icons" />
                    {item.mobile}{" "}
                  </span>
                </div>
                {/* <div>
                  <span className="create-trip-sub-text">
                    <LocationOnOutlinedIcon className="trip_transporter_icons" />{" "}
                    {item.address.district},{item.address.state}
                  </span>
                </div> */}
              </div>
            </div>
          ),
        };
      });
      setTransporterListOption(optionsList);
      return optionsList;
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      //
    }
  };

  const handleFetchDrivers = async (searchValue) => {
    const response = await GetDrivers(searchValue);

    return response.drivers_list.map((item) => {
      return {
        value: item.driving_licence_number,
        label: (
          <div>
            <div>
              <span className="create-trip-select-list-right-text">
                {item.first_name + " "}
                {item.last_name ? item.last_name : ""}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                DL no :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.driving_licence_number}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                Mobile :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.mobile_number}
              </span>
            </div>
          </div>
        ),
        first_name: item.first_name,
        last_name: item.last_name,
        mobile_number: item.mobile_number,
        alternate_mobile_number: item.alternate_mobile_number,
        pan_number: item.pan_number,
        driving_licence_number: item.driving_licence_number,
        id: item.id,
      };
    });
  };

  const handleClearDriver = () => {
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
    setDriverId("");
  };

  const handleClearSecondaryDriver = () => {
    setSecondaryDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
    setSecondaryDriverId("");
  };

  const handleTripCostChange = (e, index) => {
    let key = e.target.name;
    let value = e.target.value;
    let tripCostTemp = [...tripCost];
    tripCostTemp[index][key] = value;
    setTripCost([...tripCostTemp]);
    console.log("tripcost", tripCost);

    setErrTrip({ ...errTrip, errTotalCost: "", errAdvanceCost: "" });
  };

  const handleCloseDriverDrawer = () => {
    toggleDrawer("right", false);
    setErrorAddressName("");
    setErrorAddressNumber("");
    setErrorAddressPincode("");
    setErrorAddressVillage("");
    setCustomerDetails({
      errName: "",
      errLogo: "",
    });
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
    });
  };

  const handleCloseCustomerDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleInvoiceChange = async (e) => {
    const duplicateInvoice = invoiceId.filter(
      (invoice) => invoice[0] === e.value[0]
    );
    if (duplicateInvoice.length === 0) {
      setInvoiceId([...invoiceId, e.value]);
    } else {
      setErrorMsg(`Invoice Number ${e.value[0]} already added`);
      setOpenToast(true);
    }

    if (
      invoiceId.length > destinationDetails.length - 1 &&
      loadSource == "trip_invoice"
    ) {
      // handleAddDestination();
    }
    const tempIndex = invoiceId.length;

    handleGetAddressDestination(
      e.address.zipcode,
      tempIndex,
      e.address.attention,
      e.contactNumber,
      e.address.address
    );
    invoiceQuantityUpdate(e.value[1]);
    setSearchResultsList([]);
    setReferenceNumberSearchValue("");
    e.stopPropagation();
  };

  const handleClearReferenceSearch = () => {
    setReferenceNumberSearchValue("");
    setSearchResultsList([]);
  };

  const handleFetchInvoices = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetInvoices(searchValue);

    setSearchInvoiceLoading(false);
    setSearchResultsList(
      response.invoices
        .filter(
          (invoice) =>
            invoice.status.toLowerCase() === "draft" ||
            invoice.status.toLowerCase() === "overdue" ||
            invoice.status.toLowerCase() === "sent"
        )
        .map((item) => {
          return {
            value: [item.invoice_number, item.invoice_id],
            // value: item.invoice_number,
            label: item.invoice_number,
            address: item.shipping_address,
            contactNumber: item.custom_field_hash.cf_customer_contact,
          };
        })
    );

    return response.invoices
      .filter(
        (invoice) =>
          invoice.status.toLowerCase() === "draft" ||
          invoice.status.toLowerCase() === "overdue" ||
          invoice.status.toLowerCase() === "sent"
      )
      .map((item) => {
        return {
          value: [item.invoice_number, item.invoice_id],
          // value: item.invoice_number,
          label: item.invoice_number,
          address: item.shipping_address,
          contactNumber: item.custom_field_hash.cf_customer_contact,
        };
      });
  };

  const handleGetAddressDestination = async (pincode) => {
    const response = await GetAddressFromPincode(pincode);

    if (response) {
      setAddressDetailsDestination({
        district: response.district,
        sub_district: response.district,
        state: response.state,
        postoffice: response.postoffice,
        type: "destination",
      });
      // const source = sourceDetails;
      // source[index]["district"] = response.district;
      // source[index]["sub_district"] = response.sub_district;
      // source[index]["state"] = response.state;
      // source[index]["postoffice"] = response.office[0].name;
      // source[index]["postcode"] = response.pincode;
      setDestinationVillagesList(response.area);
    } else {
      setErrorAddressPincode("This Pincode not serviceable");
      // setErrPincodeNotServiceable("This Pincode not serviceable");
    }
    // if (response) {
    //   const destination = [...destinationDetails];
    //   if (index >= destination.length) {
    //     destination.push({
    //       concern_person_name: "",
    //       concern_person_mobile_number: "",
    //       postcode: "",
    //       type: "destination",
    //       village: "0",
    //       postoffice: "",
    //       sub_district: "",
    //       district: "",
    //       state: "",
    //       landmark: "",
    //       latitude: "",
    //       longitude: "",
    //     });
    //   }
    //
    //
    //   destination[index]["district"] = response.district;
    //   destination[index]["sub_district"] = response.sub_district;
    //   destination[index]["state"] = response.state;
    //   destination[index]["postoffice"] = response.office[0].name;
    //   destination[index]["postcode"] = response.pincode;
    //   if (city || phone || name) {
    //     destination[index]["concern_person_name"] = name;
    //     destination[index]["concern_person_mobile_number"] = phone;
    //     destination[index]["village"] = city;
    //   }

    //   setDestinationVillagesList(response.office[0].villages);
    //   setDestinationDetails(destination);
    //
    // } else {
    //   setErrPincodeNotServiceable("This Pincode not serviceable");
    // }
  };

  //Drawer starts

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    // if (currentTab == "driver" && open == false) {
    //   resetFormDriver();
    // }
  };

  //Drawer ends

  //react-select starts//
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleFetchPurchaseOrders(inputValue);
      resolve(response);
    });

  const promiseOptionsInvoices = (inputValue) =>
    new Promise((resolve) => {
      //
      let response = [];
      if (invoiceButtonPressed) {
        response = handleFetchInvoices(inputValue);
      }

      resolve(response);
    });
  //react-select-ends//

  const vehicleOptions = (inputValue, source) =>
    new Promise((resolve) => {
      const response = handleFetchVehicles(inputValue, source);

      resolve(response);
    });

  const driverOptions = async (inputValue) => {
    try {
      var response = await handleFetchDrivers(inputValue);
      setDriverListOptions(response);
      return response;
    } catch (error) {}
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  function capitalizeLetter(str) {
    var i,
      splittedArray = str?.split("_");
    for (i = 0; i < splittedArray?.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray?.join(" ");
  }
  const handleSource = () => {
    // setCurrentSourceId(index + 1);

    toggleDrawer("right", true);
    setCurrentTab(`source`);
    // handleAddSource();
  };
  const handleDestination = () => {
    // setCurrentSourceId(index + 1);

    toggleDrawer("right", true);
    setCurrentTab(`destination`);
    // handleAddSource();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const clearEwayBillData = () => {
    if (checked == true) {
      setEwayBillNumber("");
      setEwayBillFrom("");
      setEwayBillTo("");
      setEwayBillAttachment(null);
    }
  };
  useEffect(() => {
    clearEwayBillData(checked);
  }, [checked]);

  //
  const handleClick = (v, index) => {
    setNewSourceDetails(v);
    setEditId(v.id);
    setSelectedIndex(index);
    // setDeletedIdArray([...myNewArray, v.id]);

    // setEditAddressDeleteIndex(indexDelete);
    toggleDrawer("right", true);
    setCurrentTab(`source`);
  };
  const handleCloseFullPageLoad = () => {
    setLoadingDataForEdit(false);
  };
  const handleOpenFullPageLoad = () => {
    setLoadingDataForEdit(true);
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (
      removedStringForTripType(trip_type) === "first_mile" &&
      selectedCustomer?.id === selectedBillToParty?.id
    ) {
      setPaymentTerms("to_be_billed");
    } else {
      setPaymentTerms("");
    }
    // if {
    //   setPaymentTerms("");
    // }
  }, [
    selectedCustomer?.id,
    selectedBillToParty?.id,
    removedStringForTripType(trip_type),
  ]);

  const minimumDate = (() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().split("T")[0];
  })();

  const maximumDate = (() => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date.toISOString().split("T")[0];
  })();

  const checkIsMandatoryEwayBill = () => {
    if (ewayBillFrom || ewayBillTo) {
      if (ewayBillTo >= tripStartDate && ewayBillFrom <= tripStartDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  // ================ leapsupply teams ===============

  const fetchTeams = async (searchValue, status, page) => {
    try {
      const response = await getTeams(searchValue, status, page);
      if (response.status === 200) {
        setTeamsList(response.data.teams_list);
      } else if (response.status === 401) {
        toast.error("401: Not enough permissions to fetch teams list");
      }
    } catch (error) {
      console.log("Getting error while fetching teams list," + error);
    }
  };

  useEffect(() => {
    if (teamsSearchTerm.length > 2 || teamsSearchTerm == "") {
      fetchTeams(teamsSearchTerm, true, 1);
    }
  }, [teamsSearchTerm]);

  const calculateAvancePrice = (percent, totalAmount, index, keyName) => {
    // ((percent / 100) * total).toFixed(2)
    let value = (percent / 100) * totalAmount;
    let tripCostTemp = [...tripCost];
    tripCostTemp[index][keyName] = value;
    setTripCost([...tripCostTemp]);
    setErrTrip({ ...errTrip, errTotalCost: "", errAdvanceCost: "" });
  };

  return (
    <Create_Trip_Main_Container
      className="create-trip-main-container"
      // style={{ backgroundColor: "red" }}
    >
      <ToastContainer position="bottom-right" />
      {editMode ? (
        <Dialog
          onClose={handleCloseFullPageLoad}
          onClick={(e) => e.stopPropagation()}
          open={loadingDataForEdit}
          style={{ backgroundColor: "none" }}
          // disabled={loading}
        >
          <div
            style={{
              padding: ".5rem 1rem",
              // display: "flex",
              width: "200x",
              height: "150px",
              // flexDirection: "column",
            }}
          >
            <div>
              <img
                src={success}
                alt=""
                width="120px"
                height="90px"
                // style={{ marginTop: "200px" }}
              />
              <div style={{ color: "grey" }}>loading data...</div>
            </div>
          </div>
        </Dialog>
      ) : null}
      <Modal
        centered
        open={facilitatedByModal}
        onOk={() => setFacilitatedByModal(false)}
        onCancel={() => setFacilitatedByModal(false)}
        footer={null}
        zIndex={9999}
        width={700}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            gap: "40px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "start" }}
          >
            Trip Facilitated By{" "}
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
              {facilitatedBy}
            </span>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              Choose the Appropriate one<sup>*</sup>
            </div>
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={
                  facilitatedBy ===
                  "CONNECT INDIA E-COMMERCE SERVICES PVT. LTD."
                    ? {
                        width: "100px",
                        height: "100px",
                        padding: "15px",
                        border: "2px dashed #101010",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }
                    : {
                        width: "100px",
                        height: "100px",
                        padding: "15px",
                        border: "2px dashed #717171",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }
                }
                onClick={() =>
                  setFacilitatedBy("CONNECT INDIA E-COMMERCE SERVICES PVT LTD")
                }
              >
                <img
                  src={CILogo}
                  alt="Connect India"
                  style={{ width: "100%" }}
                />
              </div>
              <div>OR</div>
              <div>
                <div
                  style={
                    facilitatedBy === "FAARM'S GLOBAL TECH VENTURE PVT. LTD."
                      ? {
                          width: "100px",
                          height: "100px",
                          padding: "15px",
                          border: "2px dashed #101010",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }
                      : {
                          width: "100px",
                          height: "100px",
                          padding: "15px",
                          border: "2px dashed #717171",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }
                  }
                  onClick={() =>
                    setFacilitatedBy("FAARMS GLOBAL TECH VENTURE PVT LTD")
                  }
                >
                  <img
                    src={FaarmsLogo}
                    alt="Faarms"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonRectangle
              disabled={!facilitatedBy}
              style={
                !facilitatedBy
                  ? { backgroundColor: "#454545", cursor: "not-allowed" }
                  : {}
              }
              onClick={() => {
                handleCreateTrip();
              }}
            >
              {loadingButton ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Submit"
              )}
            </ButtonRectangle>
          </div>
          <div>
            {errCreateTripFinal ? (
              <div className="create-trip-alert">
                <Alert severity="error">{errCreateTripFinal}</Alert>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={openSummary}
        onOk={() => setOpenSummary(false)}
        onCancel={() => setOpenSummary(false)}
        footer={null}
        width={1200}
        height={1000}
      >
        <Summary_container className="summary_container">
          <div className="summary_header">
            <h3>Trip Summary</h3>
            <button
              className="edit_summary_button"
              onClick={() => {
                setOpenSummary(false);
                setErrCreateTripFinal("");
              }}
            >
              <ModeEditOutlineOutlinedIcon style={{ fontSize: "15px" }} />
              Edit
            </button>
          </div>
          <div className="summary_details_container">
            <div className="summary_details_trip_type">
              <h3>Trip Details</h3>
              <div className="trip_type_summary">
                <h3>
                  <LocationOnOutlinedIcon
                    style={{
                      color: "#0FBC9E",
                    }}
                  />
                  {capitalizeLetter(removedStringForTripType(trip_type))} Trip
                </h3>
              </div>
            </div>
            <div className="summary_content_wrapper1">
              <div className="summary_customer_address">
                <div className="summary_customer_wrapper_address">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    ></div>
                    <div>
                      <div className="trip-list-address">
                        <div className="trip-location-list">
                          {sourceAddressList?.map((source) => {
                            return (
                              <div className="trip-location-text">From</div>
                            );
                          })}
                          {/* <div className="trip-location-text">From</div> */}
                          {destinationAddressList?.map((destinations) => {
                            return <div className="trip-location-text">To</div>;
                          })}
                        </div>
                        <div className="trip-list-path">
                          <div className="trip-circle"></div>
                          <div
                            className="mini-trip-rectangle"
                            style={{
                              backgroundColor: "grey",
                              height: "10px",
                              width: "2px",
                            }}
                          ></div>
                          {sourceAddressList?.map((source, index) => {
                            return (
                              <>
                                {index == 0 ? null : (
                                  <>
                                    <div className="trip-circle"></div>
                                    <div
                                      className="mini-trip-rectangle"
                                      style={{
                                        backgroundColor: "grey",
                                        height: "10px",
                                        width: "2px",
                                      }}
                                    ></div>
                                  </>
                                )}
                              </>
                            );
                          })}

                          {destinationAddressList?.map(
                            (destinations, index) => {
                              return (
                                <>
                                  {index == 0 ? null : (
                                    <>
                                      <div className="trip-circle"></div>
                                      <div
                                        className="mini-trip-rectangle"
                                        style={{
                                          backgroundColor: "grey",
                                          height: "10px",
                                          width: "2px",
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </>
                              );
                            }
                          )}
                          <div className="trip-circle"></div>
                        </div>
                        <div className="trip-location-list">
                          {sourceAddressList?.map((source) => {
                            return (
                              <div className="trip-location-text-summary">
                                {source.village === source.district
                                  ? source.village
                                  : `${source.village},
                          ${source.district}`}{" "}
                                , {source.state}, {source.postcode}
                              </div>
                            );
                          })}

                          {destinationAddressList?.map((destinations) => {
                            return (
                              <div className="trip-location-text-summary">
                                {destinations.village === destinations.district
                                  ? destinations.district
                                  : `${destinations.village}, ${destinations.district}`}
                                , {destinations.state}, {destinations.postcode}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary_customer">
                <div className="summary_customer_wrapper">
                  <div className="summary_details_text_customer_details">
                    <div className="customer_tag">Consignor:</div>{" "}
                    <div className="customer_value">
                      {selectedCustomer.name}
                    </div>
                  </div>
                  <div className="summary_details_text_customer_details">
                    {" "}
                    <div className="customer_tag">Consignee:</div>
                    <div className="customer_value">{consigneeSearchValue}</div>
                  </div>
                  <div className="summary_details_text_customer_details">
                    <div className="customer_tag">Bill to party:</div>{" "}
                    <div className="customer_value">
                      {selectedBillToParty.name}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="summary_content_wrapper2">
              <div className="summary_vehicle">
                <div className="summary_content_heading">Vehicle details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text"> {vehicleNumber} </div>
                <div>
                  {capitalizeLetter(vehicleDetails?.manufacturer)}{" "}
                  {capitalizeLetter(vehicleDetails?.model)}
                </div>
                <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">Driver details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text">
                  {selectedDriverDetails.first_name
                    ? selectedDriverDetails.first_name
                    : driverDetails.first_name}{" "}
                  {selectedDriverDetails.last_name
                    ? selectedDriverDetails.last_name
                    : driverDetails.last_name}
                </div>
                <div>
                  DL no :{" "}
                  {selectedDriverDetails.dl
                    ? selectedDriverDetails.dl
                    : driverDetails.driving_licence_number}
                </div>
                <div>
                  Mobile :{" "}
                  {selectedDriverDetails.mobile
                    ? selectedDriverDetails.mobile
                    : driverDetails.mobile_number}
                </div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">
                  Transporter details
                </div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text">
                  {transporterDetails.transporter}
                </div>
                <div>Code : {transporterDetails.code}</div>
                <div>Mobile : {transporterDetails.mobile}</div>
              </div>
            </div>
            <div className="summary_content_wrapper3">
              <div className="summary_vehicle">
                <div className="summary_content_heading">Basic details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />

                {/* <div className="summary_details_text">
                  Load Unit :{loadUnit ? loadUnit.toUpperCase() : ""}{" "}
                </div> */}
                <div className="summary_details_text">
                  Quantity in tonnes :
                  {qunatityInTonnes ? qunatityInTonnes : "--"}{" "}
                </div>
                <div>Number of pieces : {totalQty}</div>
                <div>Invoice Value :₹{valueOfGoods}</div>
                <div>Estimate distance : {tripDistance}Kms</div>
                <div>Per Km rate : ₹{perKmRate}/km</div>
                <div>Trip Start Date : {tripStartDate}</div>
                <div>Trip start odometer reading : {tripStartMeterReading}</div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">Payment details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                {/* billedByCustomer && advanceByCustomer && tripCost.length */}
                <div className="summary_details_text">
                  Payment terms :
                  {tripType === "last_mile" || tripType === "middle_mile"
                    ? "To be billed"
                    : capitalizeLetter(paymentTerms)}
                </div>
                <div>
                  Total transport cost :{" "}
                  {tripCost
                    .filter((data) => data.cost_type === "trip_cost")
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                <div>
                  Transporter advance :
                  {tripCost
                    .filter((data) => data.cost_type === "advance")
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                <div>
                  Loading Labour Charge :
                  {tripCost
                    .filter(
                      (data) => data.cost_type === "loading_labour_charges"
                    )
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                <div>
                  Loading Detention Charge :
                  {tripCost
                    .filter(
                      (data) => data.cost_type === "loading_detention_charges"
                    )
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                {/* <div>Advance paid by customer :₹{advanceByCustomer}</div> */}
                {/* <div>Customer billed amount :₹{billedByCustomer}</div> */}
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>

                {/* <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div> */}
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">
                  E-way bill details and attachments
                </div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                {/* ewayBillNumber &&
            ewayBillTo &&
            ewayBillAttachment?.name &&
            ewayBillFrom */}
                <div className="summary_details_text">
                  E-way bill number :{ewayBillNumber ? ewayBillNumber : "--"}
                </div>
                <div>
                  E-way bill from :{ewayBillFrom ? ewayBillFrom : "--"}{" "}
                </div>
                <div>E-way bill till : {ewayBillTo ? ewayBillTo : "--"} </div>
                <div>
                  LR Number :{" "}
                  {referenceNumberManual ? referenceNumberManual : ""}
                </div>
                <div>
                  Customer Invoice No. :{" "}
                  {customerInvoiceNo ? customerInvoiceNo : "-"}
                </div>
                <div>
                  Customer Invoice Date :
                  {customerInvoiceDate ? customerInvoiceDate : "-"}
                </div>
                <div style={{ visibility: "hidden" }}>
                  Customer Invoice No. :{" "}
                </div>
              </div>
            </div>
            <div className="summary_content_wrapper4">
              <div></div>
            </div>
            <div className="summary_content_wrapper4">
              <div>
                {/* <div className="create-trip-button-container"> */}
                <button
                  className="create-trip-button-summary"
                  onClick={() => {
                    setFacilitatedByModal(true);
                  }}
                >
                  {"Create Trip"}
                </button>
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {permission ? (
                    <Alert severity="error">{permission}</Alert>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Summary_container>
      </Modal>
      <Modal
        // title="Something went wrong"
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        zIndex={9999}
        width={isMobile ? "95vw" : "50vw"}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`${
              editMode
                ? `/ftl-trips/create-tripV2/Customer_first_mile/${tripId}/update-trip`
                : `/ftl-trips/create-tripV2/Customer_first_mile`
            }`}
            errorMessage={"updating trip"}
            buttonText="Close"
          />
        </div>
      </Modal>

      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Create trip" />
        </div>
      ) : (
        <div className="create-trip-sidebar-container">
          <SideBarMain />
        </div>
      )}

      <div className="create-trip-right-container">
        <div className="create_trip_top_Section">
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // marginBottom: "30px",
              fontWeight: "500",
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          >
            <ArrowBack
              onClick={handleGoBack}
              style={{
                color: colorPalette.primaryColor,
                marginRight: "14px",
                cursor: "pointer",
              }}
            />
            {editMode ? "Update Trip" : "Create Trip"}
          </span>
          <span
            className="trip_type_text"
            style={{
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          >
            <LocationOnOutlinedIcon />{" "}
            {capitalizeLetter(
              removedStringForTripType(trip_type)
                ? removedStringForTripType(trip_type)
                : ""
            )}
          </span>
        </div>

        {/* <div className="create-trip-group-card"> */}
        <div className="create-trip-from-head"></div>

        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg ? errorMsg : ""}
          </Alert>
        </Snackbar>

        <div className="create-trip-from-body">
          <div
            className="trip_top_container"
            style={{
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          >
            <div className="create-trip-from-row">
              <div className="create-trip-input-container-customer-type">
                <h4 className="create-trip-input-head">
                  {" "}
                  Bill To Party <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      tripDetailsFromRedux?.bill_to_party_details
                        ? tripDetailsFromRedux?.bill_to_party_details
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedBillToParty(newValue);
                        setBillToSearchValue(newValue.name);
                        setCustomerList([]);
                      } else {
                        setSelectedBillToParty({});
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setBillToSearchValue(newInputValue);
                      }
                    }}
                    style={{ width: "100%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search consigner" />
                    )}
                  />
                </div>
                <div className="create-trip-error">
                  {Object.keys(selectedBillToParty).length > 0
                    ? null
                    : errTrip.errBillToParty}
                </div>
              </div>
              <div
                className="create-trip-input-container-customer-type"
                style={{
                  width: selectedCustomer?.name
                    ?.toLowerCase()
                    .includes("faarms")
                    ? "30%"
                    : "100%",
                }}
              >
                <h4 className="create-trip-input-head">
                  {Object.keys(selectedCustomer).length > 0
                    ? "Consignor"
                    : "Consignor"}{" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    label="customer"
                    defaultValue={
                      tripDetailsFromRedux?.customers_details
                        ? tripDetailsFromRedux?.customers_details
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedCustomer(newValue);
                        setConsignorId(newValue.id);
                        setCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        if (!newValue.name.toLowerCase().includes("faarms")) {
                          setLoadSource("manual");
                        }
                      } else {
                        setSelectedCustomer({});

                        setConsignorId("");
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setCustomerSearchValue(newInputValue);
                      }
                    }}
                    style={{
                      width: "100%",
                    }}
                    onKeyPress={(e) => {
                      handlePress(e);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search consignor"
                        name="consignor"
                      />
                    )}
                  />
                </div>
                <div className="create-trip-error">
                  {Object.keys(selectedCustomer).length > 0
                    ? null
                    : errTrip.errCustomer}
                </div>
              </div>

              <div className="create-trip-input-container-customer-type">
                <h4 className="create-trip-input-head">
                  Consignee <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <input
                    style={{
                      width: "100%",
                      padding: "9px",
                      fontSize: "16px",
                      outline: "none",
                      borderColor: "0.5px solid #fefefe",
                    }}
                    value={consigneeSearchValue}
                    placeholder="Enter Consignee"
                    onChange={(event) => {
                      let inputValue = event.target.value;
                      inputValue = inputValue.replace(/[^a-zA-Z0-9-.]/g, "");
                      event.target.value = inputValue;

                      setConsigneeSearchValue(event.target.value);
                    }}
                  />
                </div>

                {/* <div className="create-trip-error">
                  {Object.keys(selectedConsignee).length > 0
                    ? null
                    : errTrip.errConsignee}
                </div> */}
              </div>
            </div>

            <div className="create-trip-from-row">
              {/* {sourceDetails.length == index + 1 ? ( */}
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select vehicle<sup style={{ color: "red" }}>*</sup>
                </h4>
                <AsyncSelect
                  className="create-trip-input"
                  style={{
                    width: isMobile ? "100%" : "",
                  }}
                  defaultOptions
                  key={loadSource}
                  placeholder="search vehicle number..."
                  value={
                    vehicleNumber !== "0"
                      ? { value: vehicleNumber, label: vehicleNumber }
                      : ""
                  }
                  onChange={(e) => {
                    setVehicleDetails({
                      manufacturer: e.vehicle_manufacturer_name,
                      model: e.vehicle_type,
                      payout: e.payout_period,
                      status: e.status,
                    });

                    setVehicleNumber(e.value);
                    setVehiclePayoutPeriod(e.payout_period);
                    if (e.payout_period == "monthly_wise") {
                      setPerKmRate(e.extra_per_km_rate);
                    } else {
                      let tempperKm =
                        (tripCost.length > 0 ? tripCost[1].cost_amount : 0) /
                        tripDistance;
                      setPerKmRate(tempperKm.toFixed(2));
                    }
                  }}
                  loadOptions={(inputValue) =>
                    vehicleOptions(inputValue, loadSource)
                  }
                />

                <div className="create-trip-error">
                  {vehicleNumber == "0" ? errTrip.errVehicleNumber : null}
                </div>
                <div className="create-trip-error">
                  {vehicleDetails.status == "blocked" ||
                  vehicleDetails.status == "in_transit" ||
                  vehicleDetails.status == "not_available"
                    ? "Vehicle is not available."
                    : null}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select Driver<sup style={{ color: "red" }}>*</sup>
                </h4>
                <div className="create-trip-driver-input">
                  <AsyncSelect
                    className="create-trip-input-driver"
                    defaultOptions
                    key={driverId}
                    placeholder="Search Name or DL No."
                    value={
                      driverId
                        ? { value: driverId, label: driverDetails.first_name }
                        : ""
                    }
                    // value={driverDetails}
                    onChange={(e) => {
                      setSelectedDriverDetails({
                        first_name: e.first_name,
                        last_name: e.last_name,
                        dl: e.driving_licence_number,
                        mobile: e.mobile_number,
                      });
                      //
                      // setDriverDetails(e);
                      handleDriverSelect(e);
                    }}
                    // loadOptions={driverOptions}
                    loadOptions={(inputValue, callback) => {
                      if (inputValue.trim() || driverListOptions.length === 0) {
                        driverOptions(inputValue).then((options) => {
                          const validOptions = Array.isArray(options)
                            ? options
                            : [];
                          callback(validOptions);
                        });
                      } else {
                        const validOptions = Array.isArray(driverListOptions)
                          ? driverListOptions
                          : [];
                        callback(validOptions);
                      }
                    }}
                  />
                  {driverDetails.first_name ? (
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleClearDriver}
                    />
                  ) : (
                    <div
                      // className="create-trip-addd"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyItems: "center",
                        border: "none",
                        width: isMobile ? "18%" : "12%",
                        height: "38px",
                        cursor: "pointer",
                        marginLeft: "0px",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                        borderRadius: "0px",
                        background: "#D7F2C9",
                        color: colorPalette.primaryColor,
                        // flexDirection: "column",
                        padding: "2px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                      onClick={handleAddDriver}
                    >
                      {" "}
                      <AddCircle
                        style={{
                          fontSize: "13px",
                          // height: "100%",
                          color: colorPalette.primaryColor,
                        }}
                      />
                      <span>Add</span>{" "}
                    </div>
                  )}
                </div>
                <div className="create-trip-error">
                  {errDriverFinal ? errDriverFinal : null}
                </div>
              </div>
              {/* Secondary Driver */}
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  Select Secondary Driver
                </h4>
                <div className="create-trip-driver-input">
                  <AsyncSelect
                    className="create-trip-input-driver"
                    defaultOptions
                    key={secondaryDriverId}
                    placeholder="Search Name or DL No."
                    value={
                      secondaryDriverId
                        ? {
                            value: secondaryDriverId,
                            label: secondaryDriverDetails.first_name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      setSecondaryDriverDetails({
                        first_name: e.first_name,
                        last_name: e.last_name,
                        dl: e.driving_licence_number,
                        mobile: e.mobile_number,
                      });
                      handleSecondaryDriverSelect(e);
                    }}
                    loadOptions={(inputValue, callback) => {
                      if (inputValue.trim() || driverListOptions.length === 0) {
                        driverOptions(inputValue).then((options) => {
                          const validOptions = Array.isArray(options)
                            ? options
                            : [];
                          callback(validOptions);
                        });
                      } else {
                        const validOptions = Array.isArray(driverListOptions)
                          ? driverListOptions
                          : [];
                        callback(validOptions);
                      }
                    }}
                  />
                  {secondaryDriverDetails.first_name ? (
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSecondaryDriver}
                    />
                  ) : null}
                </div>
                <div className="create-trip-error">
                  {errDriverFinal ? errDriverFinal : null}
                </div>
              </div>
            </div>

            {/* <div className="create-trip-from-row">
              <div className="create-trip-input-container"></div>
            
            </div> */}

            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select Transporter<sup style={{ color: "red" }}>*</sup>
                </h4>
                <AsyncSelect
                  className="create-trip-input"
                  defaultOptions
                  key={transporterNameId}
                  placeholder="search transporter..."
                  value={
                    transporterNameId
                      ? {
                          value: transporterNameId,
                          label: selectedTransporterName,
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setTransporterDetails({
                      transporter: e.name,
                      code: e.transporter_code,
                      mobile: e.mobile,
                      district: e.district,
                      state: e.state,
                    });
                    //
                    setTransporterNameId(e.value);
                    setSelectedTransporterName(e.name);
                  }}
                  // loadOptions={(inputValue) =>
                  //   handleFetchTransporter(inputValue)
                  // }

                  loadOptions={(inputValue, callback) => {
                    // Only make the API call if there is a search input
                    if (inputValue || transporterListOption.length === 0) {
                      handleFetchTransporter(inputValue).then((options) => {
                        callback(options);
                      });
                    } else {
                      // If there is no input, provide the initial options without making an API call
                      callback(transporterListOption);
                    }
                  }}
                />

                <div className="create-trip-error">
                  {!transporterNameId ? errTrip.errTransporter : null}
                </div>
              </div>
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                  // backgroundColor: "red",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Trip Start Date
                  <sup
                    style={{
                      color: "red",
                      position: "relative",
                    }}
                  >
                    *
                  </sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Trip Start Date"
                  type="date"
                  value={tripStartDate}
                  onChange={(e) => setTripStartDate(e.target.value)}
                  InputProps={{
                    inputProps: { min: minimumDate, max: maximumDate },
                  }}
                />
                <div className="create-trip-error">
                  {/* {(ewayBillFrom && ewayBillFrom <= ewayBillTo) ||
                          editMode
                            ? null
                            : errTrip.errEwayFrom} */}
                </div>
              </div>
            </div>
            <div className="create-trip-from-row-grid">
              {/* {sourceDetails.map((item, index) => {
                return (
                  <> */}
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Trip source <sup style={{ color: "red" }}>*</sup>
                </h4>

                <div className="address_container">
                  <div
                    className="add_address_button"
                    onClick={() => {
                      handleSource();
                      setErrorInaddressSource("");
                    }}
                  >
                    <AddIcon style={{ color: "#0d6f3b" }} />
                    <span>Add Source</span>
                  </div>

                  <div className="address_list_container">
                    {sourceAddressList.length > 0
                      ? sourceAddressList.map((data, index) => (
                          <div
                            className="single_address_box"
                            // key={index}
                            // value={data}
                            // onClick={() => handleClick(data, index)}
                          >
                            <span
                              className="delete_address"
                              onClick={() => {
                                handleRemoveAddress(data, index);
                              }}
                            >
                              <CancelOutlinedIcon
                                style={{ fontSize: "14px" }}
                              />
                            </span>
                            <div className="address_line">{data.village},</div>{" "}
                            <div className="address_line">
                              {data.sub_district},
                            </div>
                            <div className="address_line">{data.district},</div>
                            <div className="address_line">{data.state},</div>
                            <div className="address_line">{data.postcode}.</div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="create-trip-error">
                  {errSourceFinal ? errSourceFinal : null}
                </div>
                <div className="create-trip-error">
                  {errorInaddressSource ? errorInaddressSource : null}
                </div>
              </div>

              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Trip Destination <sup style={{ color: "red" }}>*</sup>
                </h4>

                <div className="address_container">
                  <div
                    className="add_address_button"
                    onClick={() => {
                      handleDestination();
                      setErrorInaddressDestination("");
                    }}
                  >
                    <AddIcon style={{ color: "#0d6f3b" }} />
                    <span>Add Destination</span>
                  </div>

                  <div className="address_list_container">
                    {destinationAddressList.length > 0
                      ? destinationAddressList.map((data, index) => (
                          <div className="single_address_box">
                            <span
                              className="delete_address"
                              onClick={() => {
                                handleRemoveAddressDestination(data, index);
                              }}
                            >
                              <CancelOutlinedIcon
                                style={{ fontSize: "14px" }}
                              />
                            </span>
                            <div className="address_line">{data.village},</div>{" "}
                            <div className="address_line">
                              {data.sub_district},
                            </div>
                            <div className="address_line">{data.district},</div>
                            <div className="address_line">{data.state},</div>
                            <div className="address_line">{data.postcode}.</div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="create-trip-error">
                  {errSourceFinal ? errSourceFinal : null}
                </div>
                <div className="create-trip-error">
                  {errorInaddressDestination ? errorInaddressDestination : null}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`trip_second_container`}
            style={{
              opacity:
                selectedCustomer.name &&
                consigneeSearchValue &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                (tripStartDate || editMode)
                  ? "1"
                  : "0",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                selectedCustomer.name &&
                consigneeSearchValue &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                (tripStartDate || editMode)
                  ? "visible"
                  : "hidden",
            }}
          >
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Odometer reading (with attachment){" "}
                  {editMode ? null : <sup style={{ color: "red" }}>*</sup>}
                </h4>
                <TextField
                  size="small"
                  // type="number"
                  id="dropdown"
                  autoComplete="off"
                  className="create-trip-input dropdown-container"
                  variant="outlined"
                  placeholder="Enter meter reading"
                  name="attachment_tag"
                  // style={{ backgroundColor: "red" }}
                  value={tripStartMeterReading}
                  onChange={(e) => {
                    if (
                      /^\d+$/.test(e.target.value) &&
                      parseInt(e.target.value, 10) > 0
                    ) {
                      setTripStartMeterReadingError("");
                    } else {
                      setTripStartMeterReadingError(
                        "Please enter valid Meter reading"
                      );
                    }
                    setTripStartMeterReading(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          // backgroundColor: "red",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                        // onClick={handleAddAttachment}
                      >
                        <button
                          className="upload_button"
                          style={{ backgroundColor: "#D7F2C9" }}
                        >
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`upload`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              handleChangeTripMeterReadingAttachment(e);
                            }}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor={`upload`}
                            style={{
                              backgroundColor: "#D7F2C9",
                              // height: "100%",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              // width="20px"
                              // height="100%"
                              style={{
                                backgroundColor: "#D7F2C9",
                                // width: "100%",
                                // height: "100%",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  className="create-trip-attachment-name"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {tripStartMeterReadingAttachment.attachment_file != null
                      ? tripStartMeterReadingAttachment.attachment_file.name
                      : null}
                  </div>
                  <div>
                    {tripStartMeterReadingAttachment.attachment_file?.size
                      ? `${(
                          Number(
                            tripStartMeterReadingAttachment.attachment_file.size
                          ) /
                          1000 /
                          1000
                        ).toFixed(2)}MB`
                      : null}
                  </div>
                </div>

                <div className="create-trip-error">
                  {tripStartMeterReadingAttachment.attachment_file
                    ? tripStartMeterReadingAttachment.attachment_file.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                      ? `File size is too large.( < ${
                          process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                        }MB)`
                      : null
                    : null}
                </div>

                <div className="create-trip-error">
                  {tripStartMeterReadingError
                    ? tripStartMeterReadingError
                    : null}
                </div>

                <div className="create-trip-error">
                  {tripStartMeterReading
                    ? null
                    : errTrip.errTripStartMeterReading}
                </div>
                <div className="create-trip-error">
                  {tripStartMeterReadingAttachment.attachment_file
                    ? null
                    : errTrip.errTripStartMeterReadingAttachment}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Invoice Value <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  variant="outlined"
                  name="cost_amount"
                  id="trip_cost"
                  placeholder="Enter Invoice Value"
                  className="create-trip-input"
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  style={{ backgroundColor: "white" }}
                  value={valueOfGoods}
                  onChange={(e) => {
                    if (allRegex.postiveNumberRegex.test(e.target.value)) {
                      setInvoiceValueError("");
                    } else {
                      setInvoiceValueError("Please enter valid invoice value");
                    }
                    setValueOfGoods(e.target.value);
                  }}
                />
                <div className="create-trip-error">
                  {valueOfGoods > 0 ? null : errTrip.errValueOfGoods}
                </div>{" "}
                <div className="create-trip-error">
                  {invoiceValueError ? invoiceValueError : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 className="create-trip-input-head">
                    Expected Delivery Date
                  </h4>
                  <sup style={{ color: "red" }}>*</sup>
                </div>

                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  disabled={false}
                  placeholder="Enter expected delivery date"
                  type="date"
                  inputProps={{
                    min: minDate(),
                  }}
                  value={expectedDeliveryDate}
                  onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                />
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Operations SPOC Email <sup style={{ color: "red" }}>*</sup>
                </h4>
                {/* <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Operations SPOC Email"
                  style={{ backgroundColor: "white" }}
                  type="email"
                  name="operation_spoc"
                  onWheel={(e) => e.target.blur()}
                  value={operationsSpoc}
                  onChange={(e) => {
                    setOperationsSpoc(e.target.value);
                    handleInputField(e);
                  }}
                /> */}

                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={teamsList}
                  defaultValue={
                    selectedOperationSpoc.email
                      ? selectedOperationSpoc.email
                      : { email: "" }
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setOperationsSpoc(newValue.email);
                      console.log(newValue, "new input value onChange");
                    } else {
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (event?.type === "change") {
                      setTeamsSearchTerm(newInputValue);
                      console.log(newInputValue, "new input value");
                    }
                  }}
                  style={{ width: "100%" }}
                  className="create-trip-input"
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="create-trip-input"
                      placeholder="Search Teams"
                    />
                  )}
                /> */}
                <Select
                  value={
                    operationsSpoc
                      ? {
                          value: operationsSpoc,
                          label: operationsSpoc,
                        }
                      : ""
                  }
                  onChange={(event) => {
                    setOperationsSpoc(event.value);
                  }}
                  onInputChange={(event) => {
                    setTeamsSearchTerm(event);
                  }}
                  options={teamsList.map((team) => ({
                    value: team.email,
                    label: team.email,
                  }))}
                  // styles={{
                  //   control: (baseStyles, state) => ({
                  //     ...baseStyles,
                  //     borderColor: state.isFocused ? "grey" : "red",
                  //   }),
                  // }}
                  placeholder="Search Teams"
                />

                <div className="create-trip-error">
                  {operationsSpoc ? null : errTrip.operationSPOC}
                </div>
                <div className="create-trip-error">
                  {handleInputErr.operationSPOC
                    ? handleInputErr.operationSPOC
                    : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Estimated distance (in kms){" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Estimated Distance"
                  style={{ backgroundColor: "white" }}
                  type="number"
                  // onwheel="return false;"
                  onWheel={(e) => e.target.blur()}
                  value={tripDistance}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9.]+$/g, "");
                    e.target.value = inputValue;
                    if (
                      parseInt(e.target.value) <=
                      process.env.REACT_APP_MAX_TRIP_ESTIMATED_KM
                    ) {
                      setTripDistanceError(
                        `Distance should be greater then ${process.env.REACT_APP_MAX_TRIP_ESTIMATED_KM} KM.`
                      );
                    } else {
                      setTripDistanceError("");
                    }
                    setTripDistance(e.target.value);
                  }}
                />

                <div className="create-trip-error">
                  {tripDistance > 1 ? null : errTrip.errTripDistance}
                </div>
                <div className="create-trip-error">
                  {tripDistanceError ? tripDistanceError : null}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  No. of Pieces <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                  placeholder="Enter Number of pieces"
                  disabled={loadSource !== "trip_invoice" ? false : true}
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  value={totalQty}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9]+$/g, "");
                    e.target.value = inputValue;
                    setTotalQty(e.target.value);
                  }}
                />
                <div className="create-trip-error">
                  {totalQty > 0 ? null : errTrip.no_of_pieces}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Quantity in tonnes <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Quantity in tonnes"
                  style={{ backgroundColor: "white" }}
                  type="number"
                  // onwheel="return false;"
                  onWheel={(e) => e.target.blur()}
                  value={qunatityInTonnes}
                  onChange={(e) => {
                    // if (allRegex.postiveNumberRegex.test(e.target.value)) {
                    //   setQuantityInTonnesError("");
                    // } else {
                    //   setQuantityInTonnesError("");
                    // }
                    setQuantityInTonnes(e.target.value);
                  }}
                />
                <div className="create-trip-error">
                  {qunatityInTonnes ? null : errTrip.quantity_in_tonnes}
                </div>{" "}
                <div className="create-trip-error">
                  {quantityInTonnesError ? quantityInTonnesError : null}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Per KM Rate <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Per KM Rate"
                  type="number"
                  style={{ backgroundColor: "white" }}
                  onWheel={(e) => e.target.blur()}
                  disabled={true}
                  value={perKmRate}
                  // onChange={(e) => setPerKmRate(e.target.value)}
                />
                <div className="create-trip-error">
                  {perKmRate > 0 ? null : errTrip.errPerKmRate}
                </div>
              </div>
            </div>

            {/* qunatity in tonnes  */}

            <div className="create-trip-from-row"></div>

            {/* </div> */}
          </div>
          {/* ) : null} */}
          {/* {valueOfGoods &&
          tripStartMeterReading &&
          tripStartMeterReadingAttachment.attachment_file &&
          tripDistance &&
          totalQty > 0 &&
          valueOfGoods ? ( */}
          <div
            className="trip_second_container"
            style={{
              opacity:
                (selectedCustomer.name &&
                  consigneeSearchValue &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  tripStartMeterReading &&
                  !invoiceValueError &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  !tripDistanceError &&
                  // (customerInvoiceAttachment?.name || editMode) &&
                  expectedDeliveryDate &&
                  faarmsAndConnectIndiaEmailRegex.test(operationsSpoc) &&
                  qunatityInTonnes) ||
                editMode
                  ? "1"
                  : "0",
              transition: "all 1s",
              visibility:
                (selectedCustomer.name &&
                  consigneeSearchValue &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  tripStartMeterReading &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  !tripDistanceError &&
                  !tripStartMeterReadingError &&
                  !invoiceValueError &&
                  expectedDeliveryDate &&
                  faarmsAndConnectIndiaEmailRegex.test(operationsSpoc) &&
                  qunatityInTonnes) ||
                editMode
                  ? "visible"
                  : "hidden",
            }}
          >
            {vehiclePayoutPeriod == "monthly_wise" ? null : (
              <div className="create-trip-from-row">
                {vehiclePayoutPeriod == "monthly_wise" &&
                loadSource == "trip_invoice" ? null : (
                  <div
                    className="create-trip-input-container"
                    style={{
                      width: isMobile ? "100%" : "",
                      padding: isMobile ? "0 10px" : "",
                    }}
                  >
                    <div
                      className="create-trip-drivers-box"
                      // style={{ backgroundColor: "blue", width: "60%" }}
                    >
                      {vehiclePayoutPeriod == "monthly_wise" ? null : (
                        <div
                          className="create-trip-input-container"
                          style={{
                            width: isMobile ? "100%" : "",
                            padding: isMobile ? "0 10px" : "",
                          }}
                        >
                          <h4 className="create-trip-input-head">
                            {" "}
                            Payment Terms
                            {/* {tripSettingsList.filter((data) => data.feature.name == "payment_terms")[0].default_behaviour=="optional"?():} */}
                            <sup style={{ color: "red" }}>*</sup>
                          </h4>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            onChange={(e) => setPaymentTerms(e.target.value)}
                            value={
                              tripType === "last_mile" ||
                              tripType === "middle_mile"
                                ? "to_be_billed"
                                : paymentTerms
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                value="to_payee"
                                control={<Radio />}
                                label="To Pay"
                                onChange={(e) => {
                                  setToPay(e.target.value);
                                }}
                                disabled={
                                  removedStringForTripType(trip_type) ===
                                    "first_mile" &&
                                  selectedCustomer?.id ===
                                    selectedBillToParty?.id
                                    ? true
                                    : tripType === "last_mile" ||
                                      tripType === "middle_mile"
                                    ? true
                                    : false
                                }
                              />

                              <FormControlLabel
                                value="to_be_billed"
                                control={<Radio />}
                                label="To be billed"
                                onChange={(e) => {
                                  setToBilled(e.target.value);
                                  setToPay("");
                                }}
                                disabled={
                                  removedStringForTripType(trip_type) ===
                                    "first_mile" &&
                                  selectedCustomer?.id ===
                                    selectedBillToParty?.id
                                    ? true
                                    : tripType === "last_mile" ||
                                      tripType === "middle_mile"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </RadioGroup>
                          <div className="create-trip-error">
                            {paymentTerms
                              ? paymentTerms.length > 2 ||
                                vehiclePayoutPeriod == "monthly_wise"
                                ? null
                                : errTrip.errPaymentTerms
                              : null}
                          </div>
                          <div className="create-trip-error">
                            {!paymentTerms
                              ? "Please select payment terms"
                              : null}
                          </div>
                        </div>
                      )}

                      {/* <h4 className="create-trip-input-head"> Cost details:</h4> */}
                      <div
                      // style={{
                      // width: "100%",
                      // display: "flex",
                      // width: "100%",

                      // alignItems: "flex-start",
                      // flexDirection: isMobile ? "column" : "row",
                      // flexDirection: "column",
                      // justifyContent: "space-between",
                      // }}
                      >
                        {vehiclePayoutPeriod == "monthly_wise" ? null : (
                          <>
                            <div className="create-trip-from-row">
                              <div className="create-trip-cost-input-contain">
                                <div>
                                  Total transport costs(₹){" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      position: "relative",
                                    }}
                                  >
                                    *
                                  </sup>{" "}
                                </div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  name="cost_amount"
                                  id="trip_cost"
                                  style={{ width: "90%" }}
                                  placeholder="Enter total amount"
                                  className="create-trip-cost-input"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    tripCost.length > 0
                                      ? tripCost[1].cost_amount
                                      : null
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        ₹{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    if (
                                      /^\d{0,10}(\.\d{0,2})?$/.test(
                                        Number(e.target.value)
                                      )
                                    ) {
                                      if (
                                        e.target.value < tripCost[0].cost_amount
                                      ) {
                                        setErrInTransporterAdvance(
                                          "Amount should be less than or equal to Total Transporter Cost"
                                        );
                                      } else {
                                        setErrInTransporterAdvance("");
                                      }
                                      handleTripCostChange(e, 1);
                                    }
                                  }}
                                />
                                <div className="create-trip-error">
                                  {tripCost.length > 0
                                    ? tripCost[1].cost_amount > 10 ||
                                      vehiclePayoutPeriod == "monthly_wise"
                                      ? null
                                      : errTrip.errTotalCost
                                    : null}
                                </div>
                              </div>
                              <div
                                className="create-trip-cost-input-contain"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  margin: " 0rem",
                                  // justifyContent: "center",
                                }}
                              >
                                <div>
                                  Transport Advance(₹){" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      position: "relative",
                                    }}
                                  >
                                    *
                                  </sup>
                                </div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  name="cost_amount"
                                  id="trip_cost"
                                  style={{ width: "90%" }}
                                  placeholder="Enter advance amount"
                                  value={
                                    tripCost.length > 0
                                      ? tripCost[0].cost_amount
                                      : null
                                  }
                                  onChange={(e) => {
                                    if (
                                      /^\d{0,10}(\.\d{0,2})?$/.test(
                                        Number(e.target.value)
                                      )
                                    ) {
                                      if (
                                        Number(e.target.value) >
                                          tripCost[1].cost_amount ||
                                        Number(e.target.value) < 0
                                      ) {
                                        setErrInTransporterAdvance(
                                          "Amount should be less than or equal Total Transporter Cost"
                                        );
                                      } else {
                                        setErrInTransporterAdvance("");
                                      }
                                      handleTripCostChange(e, 0);
                                    }
                                  }}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  // className="create-trip-cost-input"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        ₹{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    cursor: "pointer",
                                    justifyContent: "flex-end",
                                    width: "90%",
                                  }}
                                >
                                  {percentageList.map((item, index) => {
                                    return (
                                      <h6
                                        onClick={() =>
                                          calculateAvancePrice(
                                            item,
                                            tripCost[1].cost_amount,
                                            0,
                                            "cost_amount"
                                          )
                                        }
                                        key={index}
                                        style={{
                                          fontSize: "15px",
                                          marginLeft: 15,
                                          background: colorPalette.lightGreen,
                                        }}
                                      >
                                        {" "}
                                        {item}%
                                      </h6>
                                    );
                                  })}
                                </div>

                                <div className="create-trip-error">
                                  {tripCost.length > 0
                                    ? tripCost[0].cost_amount === ""
                                      ? true
                                      : parseInt(tripCost[0].cost_amount) < 0
                                      ? // ? errTrip.errAdvanceCost
                                        // : parseInt(tripCost[0].cost_amount) == 0
                                        errTrip.errAdvanceCost
                                      : parseInt(tripCost[0].cost_amount) <=
                                        parseInt(tripCost[1].cost_amount)
                                      ? true
                                      : errTrip.errAdvanceCost
                                    : null}
                                  {errInTransporterAdvance
                                    ? errInTransporterAdvance
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="create-trip-from-row">
                              <div className="create-trip-cost-input-contain">
                                <div>
                                  Loading Labour Charge(₹){" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      position: "relative",
                                    }}
                                  >
                                    *
                                  </sup>{" "}
                                </div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  name="cost_amount"
                                  id="trip_cost"
                                  style={{ width: "90%" }}
                                  placeholder="Enter total amount"
                                  className="create-trip-cost-input"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={tripCost[2].cost_amount}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        ₹{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    if (
                                      /^\d{0,10}(\.\d{0,2})?$/.test(
                                        Number(e.target.value)
                                      )
                                    ) {
                                      console.log("e.target.value");

                                      handleTripCostChange(e, 2);
                                    }
                                  }}
                                />
                                <div className="create-trip-error">
                                  {/* {tripCost.length > 0
                                    ? tripCost[1].loading_labour_charges > 10 ||
                                      vehiclePayoutPeriod == "monthly_wise"
                                      ? null
                                      : errTrip.errTotalCost
                                    : null} */}
                                </div>
                              </div>
                              <div className="create-trip-cost-input-contain">
                                <div>
                                  Loading Detention Charge(₹){" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      position: "relative",
                                    }}
                                  >
                                    *
                                  </sup>{" "}
                                </div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  name="cost_amount"
                                  id="trip_cost"
                                  style={{ width: "90%" }}
                                  placeholder="Enter total amount"
                                  className="create-trip-cost-input"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={tripCost[3].cost_amount}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        ₹{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    if (
                                      /^\d{0,10}(\.\d{0,2})?$/.test(
                                        Number(e.target.value)
                                      )
                                    ) {
                                      handleTripCostChange(e, 3);
                                    }
                                  }}
                                />
                                <div className="create-trip-error">
                                  {/* {tripCost.length > 0
                                    ? tripCost[1].cost_amount > 10 ||
                                      vehiclePayoutPeriod == "monthly_wise"
                                      ? null
                                      : errTrip.errTotalCost
                                    : null} */}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {editMode ? (
            <div className="trip_second_container">
              {Object.keys(selectedCustomer).length > 0 ? (
                selectedCustomer?.name
                  .toLowerCase()
                  .includes("faarms") ? null : (
                  <div className="create-trip-eway-box">
                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Number
                          {mandatoryFields.length > 0 ? (
                            <sup style={{ color: "red" }}>*</sup>
                          ) : null}
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          disabled={checked ? true : false}
                          placeholder="Enter e-way bill no. edit"
                          value={ewayBillNumber}
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              allRegex.ewayBillRegex.test(e.target.value)
                                ? setEwayBillNumberError("")
                                : setEwayBillNumberError(
                                    "Please enter valid Eway Bill Number"
                                  );
                            } else if (mandatoryFields.length > 0) {
                              allRegex.ewayBillRegex.test(e.target.value)
                                ? setEwayBillNumberError("")
                                : setEwayBillNumberError(
                                    "Please enter valid Eway Bill Number"
                                  );
                            } else {
                              setEwayBillNumberError("");
                            }
                            setEwayBillNumber(e.target.value);
                          }}
                        />

                        <div className="create-trip-error">
                          {ewayBillNumber || editMode
                            ? null
                            : errTrip.errEwayNumber}
                        </div>
                        <div className="create-trip-error">
                          {!ewayBillNumberError ? null : ewayBillNumberError}
                        </div>
                      </div>

                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Valid From
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          disabled={checked || !tripStartDate ? true : false}
                          placeholder="Enter total quantity"
                          type="date"
                          value={ewayBillFrom}
                          onChange={(e) => setEwayBillFrom(e.target.value)}
                        />
                        <div className="create-trip-error">
                          {(ewayBillFrom && ewayBillFrom <= ewayBillTo) ||
                          editMode
                            ? null
                            : errTrip.errEwayFrom}
                        </div>
                        <div className="create-trip-error">
                          {ewayBillFrom && ewayBillFrom > tripStartDate
                            ? "Trip start date should be greater than equals to Eway bill from date"
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Valid Till
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter total quantity"
                          type="date"
                          disabled={checked || !ewayBillFrom ? true : false}
                          value={ewayBillTo}
                          onChange={(e) => setEwayBillTo(e.target.value)}
                        />
                        <div className="create-trip-error">
                          {(ewayBillTo && ewayBillFrom <= ewayBillTo) ||
                          editMode
                            ? null
                            : errTrip.errEwayTo}
                        </div>

                        <div className="create-trip-error">
                          {ewayBillTo >= tripStartDate
                            ? null
                            : ewayBillTo
                            ? "Eway bill till date should be greater than equals to Trip start date"
                            : null}
                        </div>
                      </div>
                      <div
                        className="trip-list-modal-input-group"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h4 className="create-trip-input-head">
                          E-Way Bill Attachment{" "}
                          {mandatoryFields.length > 0 ? (
                            editMode ? null : (
                              <sup
                                style={{
                                  color: "red",
                                  position: "relative",
                                }}
                              >
                                *
                              </sup>
                            )
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          id="dropdown"
                          select
                          autoComplete="off"
                          style={
                            isMobile ? { width: "85%" } : { width: "100%" }
                          }
                          className="create-trip-input-attachment dropdown-container"
                          variant="outlined"
                          placeholder="Enter document type"
                          name="attachment_tag"
                          disabled={true}
                          value={"e_way_bill"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                }}
                              >
                                <button
                                  className="upload_button"
                                  style={{ backgroundColor: "#D7F2C9" }}
                                >
                                  <input
                                    name="attachment_file"
                                    id={`upload-eway`}
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={async (e) => {
                                      const newFile = await saveBlob(
                                        e.target.files[0]
                                      );
                                      setEwayBillAttachment(newFile);
                                    }}
                                    hidden
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                  <label htmlFor={`upload-eway`}>
                                    <img
                                      src={UploadImage}
                                      alt=""
                                      style={{
                                        backgroundColor: "#D7F2C9",
                                      }}
                                    />
                                  </label>
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="0" disabled>
                            Select document type
                          </MenuItem>

                          {documentTypeList.map((item) => {
                            return (
                              <MenuItem value={item}>
                                {item
                                  .split("_")
                                  .join(" ")
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <div
                          className="create-trip-attachment-name"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {ewayBillAttachment != null
                              ? ewayBillAttachment.name
                              : null}
                          </div>
                          <div>
                            {ewayBillAttachment != null &&
                            ewayBillAttachment?.size
                              ? `${(
                                  Number(ewayBillAttachment.size) /
                                  1000 /
                                  1000
                                ).toFixed(2)}MB`
                              : null}
                          </div>
                        </div>
                        <div className="error-trip">
                          {ewayBillAttachment
                            ? ewayBillAttachment.size >
                              process.env.REACT_APP_MAX_FILE_SIZE
                              ? `File size is too large.( < ${
                                  process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                                }MB)`
                              : null
                            : null}
                        </div>
                      </div>
                    </div>
                    {editMode ? null : (
                      <div className="create-trip-from-row-ewaybill">
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        E-way bill details not required.
                      </div>
                    )}

                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          LR Number <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          style={{ backgroundColor: "white" }}
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter LR Number"
                          value={referenceNumberManual}
                          onChange={(e) => {
                            !allRegex.alphanumaricWithCommaRegex.test(
                              e.target.value
                            )
                              ? setLrNumberError("Please enter valid LR number")
                              : setLrNumberError("");
                            setReferenceNumberManual(e.target.value);
                          }}
                        />
                        <div className="create-trip-error">
                          {!referenceNumberManual ? errTrip.errLrNumber : null}
                        </div>
                        <div className="create-trip-error">
                          {lrNumberError ? lrNumberError : null}
                        </div>
                      </div>
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          Customer Invoice No. (with attachment)
                          {editMode ? null : (
                            <sup style={{ color: "red" }}>*</sup>
                          )}
                        </h4>
                        <TextField
                          size="small"
                          id="dropdown"
                          autoComplete="off"
                          className="create-trip-input dropdown-container"
                          variant="outlined"
                          placeholder="Enter Customer Invoice no"
                          name="attachment_tag"
                          value={customerInvoiceNo}
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(
                              /[^0-9a-zA-Z]+$/g,
                              ""
                            );
                            e.target.value = inputValue;
                            setCustomerInvoiceNo(e.target.value);
                          }}
                          style={{ width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                }}
                              >
                                <button
                                  className="upload_button"
                                  style={{ backgroundColor: "#D7F2C9" }}
                                >
                                  <input
                                    name="attachment_file"
                                    id={`upload_customer_invoice`}
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(e) => {
                                      handleChangeCustomerInvoiceAttachment(e);
                                    }}
                                    hidden
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                  <label
                                    htmlFor={`upload_customer_invoice`}
                                    style={{
                                      backgroundColor: "#D7F2C9",
                                    }}
                                  >
                                    <img
                                      src={UploadImage}
                                      alt=""
                                      style={{
                                        backgroundColor: "#D7F2C9",
                                      }}
                                    />
                                  </label>
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <div
                          className="create-trip-attachment-name"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {customerInvoiceAttachment != null
                              ? customerInvoiceAttachment.name
                              : null}
                          </div>
                          <div>
                            {customerInvoiceAttachment != null &&
                            customerInvoiceAttachment?.size
                              ? `${(
                                  Number(customerInvoiceAttachment.size) /
                                  1000 /
                                  1000
                                ).toFixed(2)}MB`
                              : null}
                          </div>
                        </div>

                        <div className="create-trip-error">
                          {customerInvoiceAttachment
                            ? customerInvoiceAttachment.size >
                              process.env.REACT_APP_MAX_FILE_SIZE
                              ? `File size is too large.( < ${
                                  process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                                }MB)`
                              : null
                            : null}
                        </div>

                        <div className="create-trip-error">
                          {customerInvoiceNo ? null : errTrip.customer_invoice}
                        </div>
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          Customer Invoice Date
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        </h4>

                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter total quantity"
                          type="date"
                          value={customerInvoiceDate}
                          onChange={(e) =>
                            setCustomerInvoiceDate(e.target.value)
                          }
                        />
                        <div className="create-trip-error">
                          {customerInvoiceDate
                            ? null
                            : errTrip.customerInvoiceDate}
                        </div>
                      </div>
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          Transporter Invoice Number
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          style={{ backgroundColor: "white" }}
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter Transporter Invoice Number"
                          value={transporterInvoiceNumber}
                          onChange={(e) => {
                            if (
                              allRegex.alphanumaricRegex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setTransporterInvoiceNumberError("");
                            } else {
                              setTransporterInvoiceNumberError(
                                "Please enter valid Invoice Number"
                              );
                            }
                            setTransporterInvoiceNumber(e.target.value);
                          }}
                        />
                        <div className="create-trip-error">
                          {transporterInvoiceNumberError
                            ? transporterInvoiceNumberError
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : null}
            </div>
          ) : null}

          <div
            className="trip_second_container"
            style={{
              opacity:
                selectedCustomer.name &&
                consigneeSearchValue &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                tripStartMeterReading &&
                !errInTransporterAdvance &&
                !tripDistanceError &&
                !invoiceValueError &&
                paymentTerms &&
                !tripStartMeterReadingError &&
                tripStartMeterReadingAttachment.attachment_file &&
                faarmsAndConnectIndiaEmailRegex.test(operationsSpoc) &&
                tripDistance &&
                qunatityInTonnes &&
                (vehiclePayoutPeriod == "trip_wise"
                  ? tripCost.filter((data) => data.cost_type == "trip_cost")[0]
                      ?.cost_amount !== "" &&
                    tripCost.filter((data) => data.cost_type == "advance")[0]
                      ?.cost_amount !== "" &&
                    tripCost.filter(
                      (data) => data.cost_type == "loading_labour_charges"
                    )[0]?.cost_amount !== "" &&
                    tripCost.filter(
                      (data) => data.cost_type == "loading_detention_charges"
                    )[0]?.cost_amount !== ""
                  : true) &&
                totalQty > 0
                  ? "1"
                  : "0",
              display: editMode ? "none" : "",
              transition: "all 1s",
              visibility:
                selectedCustomer.name &&
                consigneeSearchValue &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                tripStartMeterReading &&
                !errInTransporterAdvance &&
                !tripDistanceError &&
                !invoiceValueError &&
                paymentTerms &&
                !tripStartMeterReadingError &&
                tripStartMeterReadingAttachment.attachment_file &&
                faarmsAndConnectIndiaEmailRegex.test(operationsSpoc) &&
                tripDistance &&
                qunatityInTonnes &&
                (vehiclePayoutPeriod == "trip_wise"
                  ? tripCost.filter((data) => data.cost_type == "trip_cost")[0]
                      ?.cost_amount !== "" &&
                    tripCost.filter((data) => data.cost_type == "advance")[0]
                      ?.cost_amount !== "" &&
                    tripCost.filter(
                      (data) => data.cost_type == "loading_labour_charges"
                    )[0]?.cost_amount !== "" &&
                    tripCost.filter(
                      (data) => data.cost_type == "loading_detention_charges"
                    )[0]?.cost_amount !== ""
                  : true) &&
                totalQty > 0
                  ? "visible"
                  : "hidden",
            }}
          >
            {Object.keys(selectedCustomer).length > 0 ? (
              selectedCustomer?.name.toLowerCase().includes("faarms") ? null : (
                <div className="create-trip-eway-box">
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Number
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        disabled={checked ? true : false}
                        placeholder="Enter e-way bill no."
                        value={ewayBillNumber}
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            allRegex.ewayBillRegex.test(e.target.value)
                              ? setEwayBillNumberError("")
                              : setEwayBillNumberError(
                                  "Please enter valid Eway Bill Number"
                                );
                          } else if (mandatoryFields.length > 0) {
                            allRegex.ewayBillRegex.test(e.target.value)
                              ? setEwayBillNumberError("")
                              : setEwayBillNumberError(
                                  "Please enter valid Eway Bill Number"
                                );
                          } else {
                            setEwayBillNumberError("");
                          }
                          setEwayBillNumber(e.target.value);
                        }}
                      />
                      <div className="create-trip-error">
                        {ewayBillNumber ? null : errTrip.errEwayNumber}
                      </div>
                      <div className="create-trip-error">
                        {!ewayBillNumberError ? null : ewayBillNumberError}
                      </div>
                    </div>

                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid From
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        disabled={checked || !tripStartDate ? true : false}
                        placeholder="Enter total quantity"
                        // disabled={loadSource !== "trip_invoice" ? false : true}
                        type="date"
                        value={ewayBillFrom}
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                        // InputProps={{
                        //   inputProps: {
                        //     min: tripStartDate,
                        //   },
                        // }}
                        onChange={(e) => setEwayBillFrom(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillFrom && ewayBillFrom <= ewayBillTo
                          ? null
                          : errTrip.errEwayFrom}
                      </div>
                      <div className="create-trip-error">
                        {ewayBillFrom && ewayBillFrom > tripStartDate
                          ? "Trip start date should be greater than equals to Eway bill from date"
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid Till
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter total quantity"
                        type="date"
                        disabled={checked || !ewayBillFrom ? true : false}
                        value={ewayBillTo}
                        onChange={(e) => setEwayBillTo(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillTo && ewayBillFrom <= ewayBillTo
                          ? null
                          : errTrip.errEwayTo}
                      </div>
                      {/* <div className="create-trip-error">
                        {ewayBillFrom <= ewayBillTo
                          ? null
                          : ewayBillTo
                          ? "Select a date greater than e-way bill valid from date."
                          : null}
                      </div> */}
                      <div className="create-trip-error">
                        {ewayBillTo >= tripStartDate
                          ? null
                          : ewayBillTo
                          ? "Eway bill till date should be greater than equals to Trip start date"
                          : null}
                      </div>
                    </div>
                    <div
                      className="trip-list-modal-input-group"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h4 className="create-trip-input-head">
                        E-Way Bill Attachment{" "}
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        select
                        autoComplete="off"
                        style={isMobile ? { width: "85%" } : { width: "100%" }}
                        className="create-trip-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Enter document type"
                        name="attachment_tag"
                        disabled={true}
                        value={"e_way_bill"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{ backgroundColor: "#D7F2C9" }}
                              >
                                <input
                                  name="attachment_file"
                                  id={`upload-eway`}
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={async (e) => {
                                    const newFile = await saveBlob(
                                      e.target.files[0]
                                    );
                                    setEwayBillAttachment(newFile);
                                  }}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label htmlFor={`upload-eway`}>
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    style={{
                                      backgroundColor: "#D7F2C9",
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="0" disabled>
                          Select document type
                        </MenuItem>

                        {documentTypeList.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item
                                .split("_")
                                .join(" ")
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("")}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <div
                        className="create-trip-attachment-name"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {ewayBillAttachment != null
                            ? ewayBillAttachment.name
                            : null}
                        </div>
                        <div>
                          {ewayBillAttachment != null &&
                          ewayBillAttachment?.size
                            ? `${(
                                Number(ewayBillAttachment.size) /
                                1000 /
                                1000
                              ).toFixed(2)}MB`
                            : null}
                        </div>
                      </div>
                      <div className="error-trip">
                        {ewayBillAttachment
                          ? ewayBillAttachment.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size is too large.( < ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB)`
                            : null
                          : null}
                      </div>
                    </div>
                  </div>
                  {mandatoryFields.length > 0 ? null : (
                    <div className="create-trip-from-row-ewaybill">
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      E-way bill details not required.
                    </div>
                  )}
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        LR Number <sup style={{ color: "red" }}>*</sup>
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        style={{ backgroundColor: "white" }}
                        className="create-trip-input"
                        variant="outlined"
                        // color="white"
                        disabled={editMode ? true : false}
                        // style={{ width: "91%" }}
                        placeholder="Enter LR Number"
                        value={referenceNumberManual}
                        onChange={(e) => {
                          !allRegex.alphanumaricWithCommaRegex.test(
                            e.target.value
                          )
                            ? setLrNumberError("Please enter valid LR number")
                            : setLrNumberError("");
                          setReferenceNumberManual(e.target.value);
                        }}
                      />
                      <div className="create-trip-error">
                        {!referenceNumberManual ? errTrip.errLrNumber : null}
                      </div>
                      <div className="create-trip-error">
                        {lrNumberError ? lrNumberError : null}
                      </div>
                    </div>
                    <div className="create-trip-input-container">
                      <h4 className="create-trip-input-head">
                        Customer Invoice No.
                        <sup style={{ color: "red" }}>*</sup>
                      </h4>
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        className="create-trip-input dropdown-container"
                        variant="outlined"
                        placeholder="Enter Customer Invoice no"
                        name="attachment_tag"
                        value={customerInvoiceNo}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          inputValue = inputValue.replace(
                            /[^0-9a-zA-Z-/,]+$/g,
                            ""
                          );
                          e.target.value = inputValue;
                          setCustomerInvoiceNo(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{ backgroundColor: "#D7F2C9" }}
                              >
                                <input
                                  name="attachment_file"
                                  id={`upload_customer_invoice`}
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={(e) => {
                                    handleChangeCustomerInvoiceAttachment(e);
                                  }}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor={`upload_customer_invoice`}
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                    // height: "100%",
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    style={{
                                      backgroundColor: "#D7F2C9",
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div
                        className="create-trip-attachment-name"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {customerInvoiceAttachment != null
                            ? customerInvoiceAttachment.name
                            : null}
                        </div>
                        <div>
                          {customerInvoiceAttachment != null &&
                          customerInvoiceAttachment?.size
                            ? `${(
                                Number(customerInvoiceAttachment.size) /
                                1000 /
                                1000
                              ).toFixed(2)}MB`
                            : null}
                        </div>
                      </div>

                      <div className="create-trip-error">
                        {customerInvoiceAttachment
                          ? customerInvoiceAttachment.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size is too large.( < ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB)`
                            : null
                          : null}
                      </div>

                      <div className="create-trip-error">
                        {customerInvoiceNo ? null : errTrip.customer_invoice}
                      </div>
                    </div>
                  </div>
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        Customer Invoice Date
                        <sup
                          style={{
                            color: "red",
                            position: "relative",
                          }}
                        >
                          *
                        </sup>
                      </h4>

                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter total quantity"
                        type="date"
                        value={customerInvoiceDate}
                        onChange={(e) => setCustomerInvoiceDate(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {customerInvoiceDate
                          ? null
                          : errTrip.customerInvoiceDate}
                      </div>
                    </div>
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        Transporter Invoice Number
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        style={{ backgroundColor: "white" }}
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter Transporter Invoice Number"
                        value={transporterInvoiceNumber}
                        onChange={(e) => {
                          if (
                            allRegex.alphanumaricRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setTransporterInvoiceNumberError("");
                          } else {
                            setTransporterInvoiceNumberError(
                              "Please enter valid Invoice Number"
                            );
                          }
                          setTransporterInvoiceNumber(e.target.value);
                        }}
                      />
                      <div className="create-trip-error">
                        {transporterInvoiceNumberError
                          ? transporterInvoiceNumberError
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </div>

          <div
            className="create-trip-attachments-box"
            style={{
              opacity:
                (selectedCustomer.name &&
                  consigneeSearchValue &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  ewayBillTo >= tripStartDate &&
                  vehicleNumber &&
                  tripStartDate >= ewayBillFrom &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  // billedByCustomer &&
                  // advanceByCustomer &&
                  tripCost.length > 0 &&
                  valueOfGoods &&
                  ewayBillNumber &&
                  ewayBillTo &&
                  (ewayBillAttachment?.name || editMode) &&
                  customerInvoiceNo &&
                  customerInvoiceDate &&
                  referenceNumberManual &&
                  !ewayBillNumberError &&
                  !transporterInvoiceNumberError &&
                  !lrNumberError &&
                  paymentTerms &&
                  !errInTransporterAdvance &&
                  !handleInputErr.operationSPOC &&
                  !invoiceValueError &&
                  !tripStartMeterReadingError &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  ewayBillFrom) ||
                checked == true ||
                editMode
                  ? "1"
                  : "0",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                (selectedCustomer.name &&
                  consigneeSearchValue &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  tripStartMeterReadingAttachment.attachment_file &&
                  tripDistance &&
                  totalQty > 0 &&
                  // billedByCustomer &&
                  // advanceByCustomer &&
                  tripCost.length > 0 &&
                  valueOfGoods &&
                  ewayBillNumber &&
                  ewayBillTo &&
                  ewayBillAttachment?.name &&
                  customerInvoiceNo &&
                  customerInvoiceDate &&
                  referenceNumberManual &&
                  !ewayBillNumberError &&
                  !transporterInvoiceNumberError &&
                  !lrNumberError &&
                  paymentTerms &&
                  !errInTransporterAdvance &&
                  !handleInputErr.operationSPOC &&
                  !invoiceValueError &&
                  !tripStartMeterReadingError &&
                  customerInvoiceAttachment?.name &&
                  ewayBillFrom) ||
                (checked == true &&
                  customerInvoiceNo &&
                  customerInvoiceDate &&
                  referenceNumberManual &&
                  customerInvoiceAttachment?.name) ||
                editMode
                  ? "visible"
                  : "hidden",
            }}
          >
            <h4
              style={{
                fontWeight: "500",
                margin: "10px 10px 0px 10px",
                fontSize: "18px",
              }}
            >
              Attachments{" "}
            </h4>
            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <div
                  style={
                    isMobile
                      ? {
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                        }
                      : {
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: 10,
                        }
                  }
                  className="trip-list-modal-attachment-container"
                >
                  <div
                    className="trip-list-modal-input-group"
                    style={
                      isMobile
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            width: "60%",
                          }
                    }
                  >
                    <h4 className="create-trip-input-head">
                      Transporter Invoice / Bhada Chithi{" "}
                      {editMode ? null : (
                        <sup
                          style={{
                            color: "red",
                            position: "relative",
                          }}
                        >
                          *
                        </sup>
                      )}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      style={isMobile ? { width: "85%" } : { width: "100%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled={true}
                      value={"Bhada Chiti"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{ backgroundColor: "#D7F2C9" }}
                            >
                              <input
                                name="attachment_file"
                                id={`upload-bhada-chiti`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={async (e) => {
                                  const newFile = await saveBlob(
                                    e.target.files[0]
                                  );
                                  setBhadaChiti(newFile);
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor={`upload-bhada-chiti`}>
                                <img
                                  src={UploadImage}
                                  alt=""
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>

                    <div
                      className="create-trip-attachment-name"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{bhadaChiti != null ? bhadaChiti.name : null}</div>
                      <div>
                        {bhadaChiti != null && bhadaChiti?.size
                          ? `${(Number(bhadaChiti.size) / 1000 / 1000).toFixed(
                              2
                            )}MB`
                          : null}
                      </div>
                    </div>

                    <div className="error-trip">
                      {bhadaChiti
                        ? bhadaChiti.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( < ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB)`
                          : null
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {/* {bhadaChiti
                        ? bhadaChiti?.type === "image/jpeg" ||
                          bhadaChiti?.type === "image/jpg" ||
                          bhadaChiti?.type === "image/png"
                          ? null
                          : "only jpeg/png format is allowed"
                        : null} */}
                    </div>
                  </div>

                  <div
                    className="trip-list-modal-input-group"
                    style={
                      isMobile
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            width: "60%",
                          }
                    }
                  >
                    <h4 className="create-trip-input-head">
                      Bilty Copy{" "}
                      {editMode ? null : (
                        <sup
                          style={{
                            color: "red",
                            position: "relative",
                          }}
                        >
                          *
                        </sup>
                      )}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      style={isMobile ? { width: "85%" } : { width: "100%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled={true}
                      value={"Bhada Chiti"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{ backgroundColor: "#D7F2C9" }}
                            >
                              <input
                                name="attachment_file"
                                id={`upload-bilty-copy`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={async (e) => {
                                  const newFile = await saveBlob(
                                    e.target.files[0]
                                  );
                                  setBiltyCopy(newFile);
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor={`upload-bilty-copy`}>
                                <img
                                  src={UploadImage}
                                  alt=""
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>

                    <div
                      className="create-trip-attachment-name"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{biltyCopy != null ? biltyCopy.name : null}</div>
                      <div>
                        {biltyCopy != null && biltyCopy?.size
                          ? `${(Number(biltyCopy.size) / 1000 / 1000).toFixed(
                              2
                            )}MB`
                          : null}
                      </div>
                    </div>

                    <div className="error-trip">
                      {biltyCopy
                        ? biltyCopy.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( < ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB)`
                          : null
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {/* {biltyCopy
                        ? biltyCopy?.type === "image/jpeg" ||
                          biltyCopy?.type === "image/jpg" ||
                          biltyCopy?.type === "image/png"
                          ? null
                          : "only jpeg/png format is allowed"
                        : null} */}
                    </div>
                  </div>

                  {isMobile ? null : (
                    <div className="trip-list-modal-row">
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {editMode ? (
          <>
            <div className="create-trip-button-container-error">
              {" "}
              <div>
                {" "}
                {errCreateTripFinal ? (
                  <div className="create-trip-alert">
                    <Alert severity="error">{errCreateTripFinal}</Alert>
                  </div>
                ) : null}
                {/* <div className="create-trip-button-container"> */}
                {/* </div> */}
              </div>{" "}
            </div>
            <div className="create-trip-button-container">
              {/* {ewayBillTo >= tripStartDate && ewayBillFrom <= tripStartDate ? ( */}
              <button
                className="create-trip-button-next-summary"
                onClick={() => {
                  // handleCreateTrip();
                  setFacilitatedByModal(true);
                  setErrDriverFinal("");
                }}
                disabled={
                  lrNumberError ||
                  (ewayBillNumberError && mandatoryFields.length <= 0) ||
                  errInTransporterAdvance ||
                  transporterInvoiceNumberError ||
                  quantityInTonnesError ||
                  tripStartMeterReadingError ||
                  invoiceValueError ||
                  tripDistanceError ||
                  ewayBillNumberError ||
                  !checkIsMandatoryEwayBill() ||
                  (customerInvoiceAttachment?.size
                    ? customerInvoiceAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (ewayBillAttachment?.size
                    ? ewayBillAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (tripStartMeterReadingAttachment?.attachment_file?.size
                    ? tripStartMeterReadingAttachment.attachment_file.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (bhadaChiti?.size
                    ? bhadaChiti.size > process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (biltyCopy?.size
                    ? biltyCopy.size > process.env.REACT_APP_MAX_FILE_SIZE
                    : false)
                }
                style={
                  lrNumberError ||
                  (ewayBillNumberError && mandatoryFields.length <= 0) ||
                  errInTransporterAdvance ||
                  transporterInvoiceNumberError ||
                  quantityInTonnesError ||
                  tripStartMeterReadingError ||
                  invoiceValueError ||
                  tripDistanceError ||
                  ewayBillNumberError ||
                  !paymentTerms ||
                  !checkIsMandatoryEwayBill() ||
                  (customerInvoiceAttachment?.size
                    ? customerInvoiceAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (ewayBillAttachment?.size
                    ? ewayBillAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (tripStartMeterReadingAttachment.attachment_file?.size
                    ? tripStartMeterReadingAttachment.attachment_file.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (bhadaChiti?.size
                    ? bhadaChiti.size > process.env.REACT_APP_MAX_FILE_SIZE
                    : false) ||
                  (biltyCopy?.size
                    ? biltyCopy.size > process.env.REACT_APP_MAX_FILE_SIZE
                    : false)
                    ? { backgroundColor: "#454545", cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Update Trip"
                )}
              </button>
              {/* ) : null} */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {permission ? (
                  <Alert severity="error">{permission}</Alert>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="create-trip-button-container"
              style={{
                opacity:
                  (selectedCustomer.name &&
                    consigneeSearchValue &&
                    selectedBillToParty.name &&
                    destinationAddressList.length > 0 &&
                    sourceAddressList.length > 0 &&
                    referenceNumberManual &&
                    transporterNameId &&
                    driverId &&
                    vehicleNumber &&
                    valueOfGoods &&
                    tripStartMeterReading &&
                    tripStartMeterReadingAttachment.attachment_file &&
                    tripDistance &&
                    totalQty > 0 &&
                    !lrNumberError &&
                    !errInTransporterAdvance &&
                    !handleInputErr.operationSPOC &&
                    !invoiceValueError &&
                    !tripStartMeterReadingError &&
                    paymentTerms &&
                    tripStartMeterReadingAttachment?.attachment_file?.size <
                      process.env.REACT_APP_MAX_FILE_SIZE &&
                    (ewayBillAttachment
                      ? ewayBillAttachment?.size <
                        process.env.REACT_APP_MAX_FILE_SIZE
                      : true) &&
                    customerInvoiceAttachment?.size <
                      process.env.REACT_APP_MAX_FILE_SIZE &&
                    bhadaChiti?.size < process.env.REACT_APP_MAX_FILE_SIZE &&
                    biltyCopy?.size < process.env.REACT_APP_MAX_FILE_SIZE &&
                    // billedByCustomer &&
                    // advanceByCustomer &&
                    tripCost.length > 0 &&
                    valueOfGoods &&
                    ((ewayBillNumber &&
                      ewayBillTo &&
                      ewayBillAttachment?.name &&
                      ewayBillFrom) ||
                      checked == true) &&
                    bhadaChiti?.name &&
                    !ewayBillNumberError &&
                    biltyCopy?.name) ||
                  tripType === "last_mile" ||
                  tripType === "middle_mile"
                    ? "1"
                    : "0",
                transition: "all 1s",
                visibility:
                  (selectedCustomer.name &&
                    consigneeSearchValue &&
                    selectedBillToParty.name &&
                    destinationAddressList.length > 0 &&
                    sourceAddressList.length > 0 &&
                    referenceNumberManual &&
                    transporterNameId &&
                    driverId &&
                    vehicleNumber &&
                    valueOfGoods &&
                    tripStartMeterReading &&
                    tripStartMeterReadingAttachment.attachment_file &&
                    tripDistance &&
                    totalQty > 0 &&
                    !lrNumberError &&
                    !errInTransporterAdvance &&
                    !handleInputErr.operationSPOC &&
                    !invoiceValueError &&
                    !tripStartMeterReadingError &&
                    paymentTerms &&
                    tripStartMeterReadingAttachment?.attachment_file?.size <
                      process.env.REACT_APP_MAX_FILE_SIZE &&
                    (ewayBillAttachment
                      ? ewayBillAttachment?.size <
                        process.env.REACT_APP_MAX_FILE_SIZE
                      : true) &&
                    customerInvoiceAttachment?.size <
                      process.env.REACT_APP_MAX_FILE_SIZE &&
                    bhadaChiti?.size < process.env.REACT_APP_MAX_FILE_SIZE &&
                    biltyCopy?.size < process.env.REACT_APP_MAX_FILE_SIZE &&
                    tripCost.length > 0 &&
                    valueOfGoods &&
                    ((ewayBillNumber &&
                      ewayBillTo &&
                      ewayBillAttachment?.name &&
                      ewayBillFrom) ||
                      (checked == true &&
                        customerInvoiceNo &&
                        customerInvoiceAttachment?.name)) &&
                    ((bhadaChiti?.name &&
                      biltyCopy?.name &&
                      !ewayBillNumberError &&
                      biltyCopy?.type === "image/jpeg") ||
                      biltyCopy?.type === "image/jpg" ||
                      biltyCopy?.type === "application/pdf" ||
                      biltyCopy?.type === "image/png") &&
                    (bhadaChiti?.type === "image/jpeg" ||
                      bhadaChiti?.type === "image/jpg" ||
                      bhadaChiti?.type === "application/pdf" ||
                      bhadaChiti?.type === "image/png")) ||
                  tripType === "last_mile" ||
                  tripType === "middle_mile"
                    ? //   attachmentListArray[0].attachment_file) ||
                      // checkedAttachmentsNotRequired ||
                      "visible"
                    : "hidden",
              }}
            >
              <button
                className="create-trip-button-next-summary"
                onClick={() => setOpenSummary(true)}
              >
                Next <NavigateNextIcon />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {permission ? <Alert severity="error">{permission}</Alert> : null}
            </div>
          </>
        )}
      </div>
      <div className="create-trip-right-most-container">
        {/*Source details start*/}
        {currentTab == "source" ? (
          // sourceDetails
          //     .filter((item, indexs) => indexs == currentSourceId - 1)
          //     .map((item, index) => {
          //       return (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrorAddressName("");
              setErrorAddressNumber("");
              setErrorAddressPincode("");
              setErrorAddressVillage("");
              selectedIndex("");
              setEditId("");
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Add Trip source
                <Close
                  color="action"
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="concern_person_name"
                    placeholder="Enter the name"
                    value={newSourceDetails.concern_person_name}
                    // value={item.concern_person_name}
                    // onChange={(e) => {
                    //   handleOnChangeSource(e);
                    // }}
                    onChange={(e) => {
                      changehandle(e);
                      setErrorAddressName("");
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressName ? errorAddressName : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person Mobile Number{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="concern_person_mobile_number"
                    placeholder="Enter the mobile number"
                    value={newSourceDetails.concern_person_mobile_number}
                    // value={item.concern_person_mobile_number}
                    // onChange={(e) => {
                    //   if (e.target.value.length <= 10) {
                    //     handleOnChangeSource(e);
                    //   }
                    // }}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        changehandle(e);
                        setErrorAddressNumber("");
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressNumber ? errorAddressNumber : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                {/* <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="Enter Pincode"
                    name="postcode"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={newSourceDetails.postcode}
                    // value={item.postcode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        setErrPincodeNotServiceable("");
                        changehandle(e);
                        setErrorAddressPincode("");
                        if (e.target.value.length == 6) {
                          handleGetAddressSource(e.target.value);
                        }
                      }
                    }}
                    // onChange={(e) => {
                    //   changehandle(e);
                    // }}
                  />
                  <div className="create-trip-error">
                    {errPincodeNotServiceable ? errPincodeNotServiceable : null}
                    {errorAddressPincode ? errorAddressPincode : null}
                  </div>
                </div> */}
              </div>
              <div className="" style={{ marginLeft: "7px" }}>
                <Pincode
                  parentStyle={{ display: "flex", flexDirection: "column" }}
                  handlePincode={handlePincodeNewSource}
                  pincodeError={errorAddressPincode}
                  inputDesign={{ marginTop: 25, width: "100%" }}
                  lastPincode={newSourceDetails.postcode}
                  inputWidth={""}
                />
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Village <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    className="create-trip-input"
                    id="outlined-select-currency"
                    name="village"
                    select
                    size="small"
                    value={newSourceDetails.village}
                    // onChange={(e) => handleOnChangeSource(e)}
                    onChange={(e) => {
                      changehandle(e);
                      setErrorAddressVillage("");
                    }}

                    // label="Select"
                    // value={currency}
                    // onChange={handleChange}
                  >
                    <MenuItem value="0" disabled>
                      Select Village
                    </MenuItem>
                    {sourceVillagesList.map((village, index) => {
                      return (
                        <MenuItem key={index} value={village}>
                          {village}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <div className="create-trip-error">
                    {errorAddressVillage ? errorAddressVillage : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Sub District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="sub_district"
                    placeholder="sub district"
                    value={addressDetails.sub_district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="District"
                    name="district"
                    value={addressDetails.district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    State <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="state"
                    placeholder="state"
                    value={addressDetails.state}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head"> Landmark</h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="Enter the landmark"
                    name="landmark"
                    value={newSourceDetails.landmark}
                    // onChange={(e) => handleOnChangeSource(e)}
                    onChange={changehandle}
                  />
                </div>
              </div>
              <div className="create-trip-input-container">
                <button
                  className="create-trip-save-btn"
                  onClick={handleSubmitSourceAddress}
                >
                  Save
                </button>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}
        {/*Source details end*/}
        {/*Destination details start*/}
        {currentTab == "destination" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrorAddressName("");
              setErrorAddressNumber("");
              setErrorAddressPincode("");
              setErrorAddressVillage("");
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Add Trip destination
                <Close
                  color="action"
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="concern_person_name"
                    placeholder="Enter the name"
                    value={newdestinationDetails.concern_person_name}
                    onChange={(e) => {
                      changehandleDestination(e);
                      setErrorAddressName("");
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressName ? errorAddressName : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person Mobile Number{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="concern_person_mobile_number"
                    placeholder="Enter the mobile number"
                    value={newdestinationDetails.concern_person_mobile_number}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        changehandleDestination(e);
                        setErrorAddressNumber("");
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressNumber ? errorAddressNumber : null}
                  </div>
                </div>
              </div>
              {/* <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="postcode"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter Pincode"
                    value={newdestinationDetails.postcode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        setErrPincodeNotServiceable("");
                        changehandleDestination(e);
                        setErrorAddressPincode("");
                        if (e.target.value.length == 6) {
                          handleGetAddressDestination(e.target.value);
                        }
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errPincodeNotServiceable ? errPincodeNotServiceable : null}
                    {errorAddressPincode ? errorAddressPincode : null}
                  </div>
                </div>
              </div> */}
              <div className="" style={{ marginLeft: "7px" }}>
                <Pincode
                  parentStyle={{ display: "flex", flexDirection: "column" }}
                  handlePincode={handlePincodeDestination}
                  pincodeError={errorAddressPincode}
                  inputDesign={{ marginTop: 25, width: "100%" }}
                  lastPincode={newdestinationDetails.postcode}
                  inputWidth={""}
                />
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Village <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    className="create-trip-input"
                    id="outlined-select-currency"
                    select
                    size="small"
                    name="village"
                    value={newdestinationDetails.village}
                    onChange={(e) => {
                      changehandleDestination(e);
                      setErrorAddressVillage("");
                    }}
                    // label="Select"
                    // value={currency}
                    // onChange={handleChange}
                  >
                    <MenuItem value="0" disabled>
                      Select Village
                    </MenuItem>
                    {destinationVillagesList.map((village, index) => {
                      return (
                        <MenuItem key={index} value={village}>
                          {village}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <div className="create-trip-error">
                    {errorAddressVillage ? errorAddressVillage : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Sub District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="sub district"
                    value={addressDetailsDestination.sub_district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="District"
                    value={addressDetailsDestination.district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    State <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="state"
                    value={addressDetailsDestination.state}
                    disabled
                  />
                </div>
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head"> Landmark</h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="landmark"
                    placeholder="Enter the landmark"
                    value={addressDetailsDestination.landmark}
                    onChange={changehandleDestination}
                  />
                </div>
              </div>

              <div className="create-trip-input-container">
                <button
                  className="create-trip-save-btn"
                  onClick={() => changhandleSubmitDestination()}
                >
                  Save
                </button>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {currentTab == "driver" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrDriverDetails({
                errFirstName: "",
                errMobileNumber: "",
                errPanNumber: "",
                errDLNumber: "",
                errPanDocs: "",
                errDLDocs: "",
              });
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Driver's details:</h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      First name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter first name"
                      value={driverDetails.first_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          first_name: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!driverDetails.first_name
                        ? errDriverDetails.errFirstName
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Last name</h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter last name"
                      value={driverDetails.last_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Mobile number <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.mobile_number < 6000000000
                        ? errDriverDetails.errMobileNumber
                        : null}
                    </div>
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Alternate Mobile number{" "}
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.alternate_mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            alternate_mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Driving license number{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter the driving licence number"
                      value={driverDetails.driving_licence_number}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          driving_licence_number: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.driving_licence_number.length >= 6
                        ? null
                        : errDriverDetails.errDLNumber}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    {/* <h4 className="create-trip-input-head">
                      {" "}
                      Upload Driving License{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4> */}
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Driving license"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                            // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploaddl`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleDrivingPhoto}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploaddl`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <input
                      type="file"
                      className="create-trip-input"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handleDrivingPhoto}
                    /> */}
                    <div className="create-trip-dl-name">
                      {drivingPhoto ? drivingPhoto.name : null}
                    </div>
                    <div className="create-trip-error">
                      {!drivingPhoto ? errDriverDetails.errDLDocs : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errTotalDriver ? (
                    <Alert severity="error">{errTotalDriver}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleDriverSave()}
                  >
                    {driverLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {currentTab === "customer" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setCustomerDetails({
                errName: "",
                errLogo: "",
              });
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Add Customer </h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter customer name"
                      // value={addCustomerData?.name}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          name: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!addCustomerData.name
                        ? errCustomerDetails.errName
                        : null}
                    </div>
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Upload Customer's logo{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Customer's logo"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                            // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploadcustlogo`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleCustomerLogo}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploadcustlogo`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="create-trip-dl-name">
                      {addCustomerData?.logo
                        ? addCustomerData?.logo?.name
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {!addCustomerData.logo
                        ? errCustomerDetails.errLogo
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Description</h4>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      // value={remarks}
                      placeholder="Write description.."
                      style={{
                        width: "80%",
                        // height: 100,
                        // marginBottom: "2rem",
                        padding: ".4rem",
                      }}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          description: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!addCustomerData.description
                        ? errCustomerDetails.errDescription
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errCustomerFinal ? (
                    <Alert severity="error">{errCustomerFinal}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleCustomerSave()}
                  >
                    {customerLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {/*Driver's details end*/}
      </div>
    </Create_Trip_Main_Container>
  );
}

export default CreateTripV2;
