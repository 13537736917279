import { useEffect, useState } from "react";

export const useDebounce = (value, delaly = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    if (value.length < 3 && value !== "") return;
    const timeout = setTimeout(() => {
      setDebounceValue(value);
    }, delaly);

    return () => clearTimeout(timeout);
  }, [value]);

  return debounceValue;
};
